import React, { Component } from 'react'
import './SiteMap.scss'

import {PageTitle} from '../../../Base/components/'
import {
    rootUrl, contactUrl, aboutUrl, careersUrl,
    simplifyCloudOpsUrl, cicdServiceUrl, containerServiceUrl, kubernetesServiceUrl,
    cloudServiceHomeUrl, azureServiceUrl, appDevelopmentServiceUrl,
    awsServiceUrl, serviceMeshServiceUrl, enterpriseAppsUrl, appModernizationServiceUrl,
} from '../../../Base/helpers';

import {
    deliveryPipelineAutomationUrl, terraformServiceUrl
} from '../../helpers/url';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAngleRight } from '@fortawesome/free-solid-svg-icons'


export class SiteMap extends Component {
    render() {
        return (
            <div className="siteMap">
                 <PageTitle text={"Site-map"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} />
                <div className="siteMapImageContainer">
                    <div className="backgroundColor"></div>
                    <img src="https://bmmagazine.co.uk/wp-content/uploads/2017/08/shutterstock_317361941-e1502451063948.jpg" alt=""/>
                    <div className="home">
                        <h6><a className="text-white" href={rootUrl()}>Home</a></h6>
                        <FontAwesomeIcon icon={faAngleRight} className="ml-2 mr-2" />
                        <h6>Sitemap</h6>
                    </div>
                    <div className="xivTech">
                        <h1> XivTech Sitemap</h1>
                        <p>Managed solutions and services at galance.</p>
                    </div>
                </div>
                <div className="sites">
                    <h1>Cloud Services</h1>
                    <ul>
                        <li><a href={cloudServiceHomeUrl()}>Cloud Services</a></li>
                        <li><a href={azureServiceUrl()}>AZURE</a></li>
                        <li><a href={awsServiceUrl()}>AWS</a></li>
                    </ul>
                </div>
                <div className="sites">
                    <h1>Delivery Pipeline</h1>
                    <ul>
                        <li><a href={deliveryPipelineAutomationUrl()}>Dev Ops</a></li>
                        <li><a href={cicdServiceUrl()}>CICD</a></li>
                        <li><a href={terraformServiceUrl()}>TerraForm</a></li>
                        <li><a href={containerServiceUrl()}>Containers-From Commit To Container</a></li>
                    </ul>
                </div>
                <div className="sites">
                    <h1>Simplify Cloud</h1>
                    <ul>
                        <li><a href={simplifyCloudOpsUrl()}>Simplify Cloud Ops</a></li>
                        <li><a href={kubernetesServiceUrl()}>Kubernetes- Enterprise-Ready Runtime</a></li>
                        <li><a href={serviceMeshServiceUrl()}>Service Mesh- App wide Networking and Control</a></li>
                    </ul>
                </div>
                <div className="sites">
                    <h1>Build Better Apps</h1>
                    <ul>
                        <li><a href={enterpriseAppsUrl()}>Enterprise - Apps</a></li>
                        <li><a href={appDevelopmentServiceUrl()}>Build Apps- Deliver New Apps users Love</a></li>
                        <li><a href={appModernizationServiceUrl()}>Modernize Apps - Rearchitect Valuable legacy Apps</a></li>
                    </ul>
                </div>
                <div className="sites">
                    <h1>About XivTech</h1>
                    <ul>
                        <li><a href={aboutUrl()}>About Us</a></li>
                        <li><a href={contactUrl()}>Contact Us</a></li>
                        <li><a href={careersUrl()}>Career</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default SiteMap
