import React, { useState,useEffect } from 'react';
import { Button, Form, Row, FormGroup, Label, Input} from 'reactstrap';
import Recaptcha from 'react-recaptcha'
import { Modal } from "react-bootstrap";
import axios from 'axios'
import Col from 'reactstrap/lib/Col';
import LoadingOverlay from 'react-loading-overlay';
import './ApplicationForm.scss'
import {PageTitle} from '../../../Base/components/'


const requiredFields = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  resume:""
};


function ApplicationForm(props) {
  const [show, setShow] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [showPop, setshowPop] = useState(false)
  const [resume, setResume] = useState(null)
  const [portfolio, setPortfolio] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [fields, setFields] = useState(requiredFields)

  const verifyCallback = (response) => {
    if (response) {
      // setdisabled(!disabled)
    }
  }

  let value = {...fields}
  const onFieldsChange = (e) =>{
    if(e.target.name == 'FirstName'){
      value.firstName = e.target.value; 
      setFields(value)
    }else
    if(e.target.name == 'LastName'){
      value.lastName = e.target.value
      setFields(value)
    }else
    if(e.target.name == 'phone'){
      value.phone = e.target.value
      setFields(value)
    }else
    if(e.target.name == 'email'){
      value.email = e.target.value
      setFields(value)
    }
    
  }

  useEffect(() => {
   if(fields.firstName && fields.lastName && fields.phone && fields.email && fields.resume){
     setdisabled(false)
   }
   else{
     setdisabled(true)
   }
  },[fields]);




  const callback = () => console.log('loaded');

  const onFileChange = (e) => {
    value.resume = e.target.files[0].name
    setFields(value)
    
    if (e.target.files[0] == undefined) {
      return
    }
    else {

      if (e.target.files[0].size > 1024000) {
        e.target.value = null;
        alert('Maximum file size exceeded')
        return
      }
    }
    Main(e.target.files[0])
    async function Main(file) {

      if (e.target.name == "resumeUpload") {

        setResume({ resume: await toBase64(file) })
      }
      else
        if (e.target.name == "portfolioUpload") {
          setPortfolio({
            portfolio: await toBase64(file)
          })
        }
    }
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  const handleSubmit = (e) => {
    e.preventDefault();


    const user = {
      firstname: e.target.FirstName.value,
      lastname: e.target.LastName.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      resume: resume,
      portfolio: portfolio,
      portfoliolink: e.target.portfolioLink.value
    }
     
    axios({
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "X-API-KEY": `${process.env.REACT_APP_FORMS_API_KEY}`
      },
      url: `${process.env.REACT_APP_POST_APPLICATION_FORMS}`,
      data: user
    }).then(function (response) {
      handleClose();
      handleshowPop();
    }).catch((err) =>{
       handleErrorOccured();
       handleClose();
       handleShow();
    })

    // axios({
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     "X-API-KEY": `${process.env.REACT_APP_FORMS_API_KEY}`
    //   },
    //   url: `${process.env.REACT_APP_EMAIL_API}`,
    //   data: {
    //     to: "majeed.sofi@xivtech.io",
    //     from: "majeed.sofi@xivtech.io",
    //     subject: "Form Submission",
    //     text: `${user.firstname + ' ' + user.lastname + ' having email ' + user.email + ' ' + 'has submitted the application form.'} for  ${props.jobTitle}`
    //   }
    // })

    setFields('')
    setdisabled(true);
    handleIsLoading()
  }
  const handleClose = () => {
    setFields('')
    setShow(false)
    setdisabled(true)
  };
  const handleShow = () => {
    setShow(true)
    setIsLoading(false)
  };
  const handleshowPop = () => setshowPop(true);
  const handleIsLoading = () => setIsLoading(!isLoading);
  const handleErrorOccured = () => setErrorOccured(!errorOccured);





  return (
    <div>
       <PageTitle text={"Application Form"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} />
       

      <>
        <Button variant="primary" className="btn btn-danger btn-lg" onClick={handleShow}>
          Apply Now
      </Button>

        <Modal show={show} onHide={handleClose} animation={false} centered className="applicationForm">
          <LoadingOverlay
            active={isLoading}
            spinner
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgba(3, 3, 3, 0.5)',
                fadeSpeed: 200
              })
            }}
          >
        
            <Modal.Header closeButton>

              <Modal.Title>Apply</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form classN="needs-validation" novalidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>

                    <FormGroup>
                      <Label for="exampleFirstName">First Name*</Label>
                      <Input type="text" name="FirstName" id="exampleFirstName" onChange={onFieldsChange} required />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleLastName">Last Name*</Label>
                      <Input type="text" name="LastName" id="exampleLastName" onChange={onFieldsChange} required />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label for="exampleEmail">Email Address*</Label>
                  <Input type="email" name="email" id="exampleEmail" onChange={onFieldsChange} required />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleNumber">Phone Number*</Label>
                  <Input type="tel" name="phone" id="exampleNumber" pattern="(?<number>(\+?(?<country>(\d{1,3}))(\s|-|\.)?)?(\(?(?<region>(\d{3}))\)?(\s|-|\.)?)((?<first>(\d{3}))(\s|-|\.)?)((?<last>(\d{4}))))" onChange={onFieldsChange} required />
                </FormGroup>
                <FormGroup>
                  <Label for="resumeUpload">Upload Resume*</Label>
                  <Input type="file" name="resumeUpload" id="resumeUpload" accept={'.pdf'} onChange={onFileChange} required />
                </FormGroup>

                <FormGroup>
                  <Label for="portfolioUpload">Upload Portfolio</Label>
                  <Input type="file" name="portfolioUpload" id="portfolioUpload" accept={'.pdf'} onChange={onFileChange} />

                </FormGroup>

                <FormGroup>
                  <Label for="portfolioLink">Portfolio Link (optional)</Label>
                  <Input type="text" name="portfolioLink" id="portfolioLink" />
                </FormGroup>

                <Recaptcha required className="m-3"
                  sitekey="6Ld9jD4aAAAAACVeNGI82b5DQOZcrVL5QNZVt45C"
                  render="explicit"
                  onloadCallback={callback}
                  verifyCallback={verifyCallback}
                />
                <br />
                <button disabled={disabled} type="submit" className="btn btn-danger m-2 btn-block af">Submit</button>
              </Form>

            </Modal.Body>

          </LoadingOverlay>
        </Modal>
      </>

      {
        <>
          <Modal show={showPop} onHide={() => setshowPop(false)}
            className="appModal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              </Modal.Title>
            </Modal.Header>
            <Modal.Body closeButton>
            {
                !errorOccured ?
                <div>
                   <h4>Thank you For Contacting Us</h4>
                <p>
                A XivTech representative will be soon in touch.
                </p>
                </div>
                :
                <div>
                  <h3>Oops!</h3>
                  <h5>
                  Your form could not be submitted due to system error. Please try again later.
                  </h5>
                </div>
              }
             
            </Modal.Body>

          </Modal>
        </>

      }

    </div>
  );
}
export default ApplicationForm;