import React, { Component } from 'react'
import "./Capabilities2.scss"

class Cap2 extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="capabilities2">
                <div className="capabilitiesTop">
                    <img src={this.props.imgLink} alt="" />
                </div>

                <div className="capabilitiesBottom">
                    <h3>{this.props.title}</h3>
                    <p>{this.props.text}</p>
                    <div className="innerImgContainer">
                        <img className="arrowImg" src="/Arrow-black.svg" alt="" /><span><a href="">{this.props.titleLink}</a></span>
                    </div>
                </div>
            </div>
        )
    }
}


export default Cap2;