import React, { PureComponent } from 'react';
import { Row, Col, Container, CardText, Jumbotron } from "reactstrap";
import './ViewServicesTile.scss';

class ViewServicesTile extends PureComponent {

    render() {

        const description =
            "Your IT should be customized to your business and delivered the way you need it." +
            " Our experts get to know what you do, and how you do it, before bringing you a highly opinionated, custom solution.";
        const header = "Proven Solutions, Powered by Experts";

        return (
            <div  >
                <Jumbotron fluid className="view-services-tile" >
                    <Container fluid>
                        <Row>
                            <Col md={{ size: 4, offset: 2 }}>
                                <h3 className="view-service-tile-header">{header}</h3>
                            </Col>

                            <Col md={{ size: 4 }}>
                                <CardText className="view-service-tile-description">{description}</CardText>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 4, offset: 8 }}>
                                {/* <Button  className="view-service-tile-btn" >See What We Do</Button>  */}
                                {/* TODO take out all the btns as common */}
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            </div >


        );
    }
}

export default ViewServicesTile;

        //
        // //
//   {/* <Col>
//                             <Card className="how-we-work-card">
//                                 <div className="how-we-work-item">
//                                     <CardBody>
//                                         {/* <CardTitle className="how-we-work-item-header h5">{quality.header}</CardTitle> */}
//                         {/* <CardText className="how-we-work-item-description">{description}</CardText>
//                                     </CardBody>
//                                 </div>
//                             </Card> */}
//                         // {/* // </Col> */} 