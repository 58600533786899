import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList,  ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl,deliveryPipelineAutomationUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './ContainerServices.scss';


class ContainerServices extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'DevOps Services', href: deliveryPipelineAutomationUrl() },
            { children: 'Container Services' }
        ]

        const serviceActionDescription =
        "We enable the cloud journey for our customers by strategizing around unique customer needs and offering expert solutions. " +
        "Our core focus on rationalization allows us to unlock the true value of the cloud, by framing tailored and risk-mitigated cloud strategy " + 
        "solutions which consider all possibilities and minimal downtime.";
    
        return (
            <div>
                <PageTitle text={"ContainerServices"} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />
                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Container Services - From Commit to Container"
                    description="Quality Software with Portability, Efficiency and Consistency."
                />
                
                <ServicesAction
                    title="Containerization: Strategy, Design, and Implementation"
                    description={serviceActionDescription}
                />

                <Container>
                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits ContainerServices"}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Containerization Advisory",
                                        // subTitle: "",
                                        description: "Our advisory experts will work with you to devise the best Containerization Strategy, tailored to your unique needs.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "Docker Containers",
                                        // subTitle: "",
                                        description: "TBD",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Container Orchestration",
                                        // subTitle: "",
                                        description: "TBD",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "Kubernetes Automation",
                                        // subTitle: "",
                                        description: "TBD",
                                        // description: "We specialize in architectural design, that accommodates hybrid private and public cloud strategies enabling you to future proof your digital transformation. Our expertise allows for a true hybrid cloud experience that increases efficiency, flexibility and productivity.",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },
                                    // {
                                    //     id: "5",
                                    //     title: "Cloud Security",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to help you transition to a fast and secure cloud. Our solutions provide guardrails to enforce security practices with the flexibility for continuous iteration that digital business demands.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "6",
                                    //     title: "Application Containerization ",
                                    //     // subTitle: "",
                                    //     description: "We help you improve agility by developing and implementing a containerization strategy suited uniquely to your needs. We employ tools and frameworks such as Docker, Kubernetes, Red Hat OpenShift and public cloud containers to devise and implement the best DevOps strategy for your business.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "7",
                                    //     title: "Cloud Native Applications",
                                    //     // subTitle: "",
                                    //     description: "Our Cloud expertise allows us to co-create and deploy cloud native applications with modern microservice and dev-ops architecture. We help transfer skiils to your team to dramatically scale innovation and expertise across your organization.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "8",
                                    //     title: "CI/CD",
                                    //     // subTitle: "",
                                    //     description: "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. We help train your team, empowering you to stay ahead of the competition.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "9",
                                    //     title: "HashiCorp",
                                    //     // subTitle: "",
                                    //     description: "Leverage native Azure services to deliver powerful applications that are economical to run",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // }, {
                                    //     id: "10",
                                    //     title: "Data Migration",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "11",
                                    //     title: "AI Services",
                                    //     // subTitle: "",
                                    //     description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "12",
                                    //     title: "Blockchain Services",
                                    //     // subTitle: "",
                                    //     description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                </Container>

            </div>
        );
    }
}


export default ContainerServices;
