import React, { PureComponent } from 'react';
import './TitleLink.scss';

class TitleLink extends PureComponent {


    render() {
        const {
            titleName,
            to
        } = this.props;
        return (

            <div className="mt-4">
                <div >
                    <p className="challenge-card-topic">
                        <a href={to} className=" challenge-card-topic-link service-item-title-link" >{titleName}</a>
                    </p>
                </div>
            </div>

        );
    }
}

export default TitleLink;
