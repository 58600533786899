import React, { PureComponent } from 'react';
import './FooterServices.scss';

class FooterServices extends PureComponent {

    render() {

        const {
            services,
        } = this.props;



        return (
            <div>
                <h7 className="footer-services-header">
                    <a className="text-white" href={'/'}>{services.header}</a>
                </h7>
                <div className="footer-services-items mt-3 d-flex flex-column justify-content-start ">
                    {
                        services.list.map(item => (
                            <p className="m-1">
                                <a className="text-white" href={item.to}>{item.serviceName} {/*key={item.id}*/}</a>
                            </p>
                        ))
                    }
                </div>

            </div>
        );
    }
}

export default FooterServices;
