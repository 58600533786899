import React, { PureComponent } from 'react';
import {PageTitle} from '../../../Base/components/'
import './Logo.scss';

class Logo extends PureComponent {
constructor(props) {
    super(props)
    console.log(this.props)
}

    render() {
        return (
            <div >
                 <PageTitle text={"XivTech"} description={"careers in xivtech"} />
                <a href="/"><h4 className={this.props.class} className="d-flex flex-row"><img src={`/${this.props.class}.svg`} className="logo1" alt="logo1" /><img className="secondImg" src={`/${this.props.imgLink}`} alt="logo2" /></h4></a>
            </div>
        );
    }
}

export default Logo;
