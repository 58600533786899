import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList, ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl, simplifyCloudOpsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './KubernetesServices.scss';


class KubernetesServices extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'Simplify Cloud Ops', href: simplifyCloudOpsUrl() },
            { children: 'Multi-Cloud Flexibility' }
        ]

        const serviceActionDescription = ` Kubernetes is to your IT infrastructure as robots are to warehouses. At Xivtech, with Kubernetes and containers we help you realize the promise of hybrid and multi-cloud. Our specialists can craft the specs for the right workloads on the right cloud while avoiding vendor lock-in. We help your business to reduce costs and realize both the short and long term ROI.`

        return (
            <div>
                <PageTitle text={"Multi-Cloud Flexibility"} description={"Helps to Build, Deliver, and Scale Containerized Apps at XivTech. Learn More! Portable. Highly Secure. Scalable. Extensible. Services: Ship Promptly, Operate with Ease."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs}/>
                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Multi-Cloud Flexibility"
                    description="Realize the Promise of Hybrid and Multi-Cloud"
                />

                <ServicesAction
                    title1 = "Cost-Effective "
                    title2 = "Efficient"
                    title3 = "Multi-Cloud"
                    description={serviceActionDescription}
                    classForGT="kubernetesServices"
                />

                {/* <Container> */}


                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits of Kubernetes Services"}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Multi-Cloud Flexibility",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "At Xivtech, with Kubernetes and containers we help you realize the promise of hybrid and multi-cloud. Our specialists craft specs for your business to avoid vendor lock-in.  ",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "Storage Orchestration",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Kubernetes automatically mounts the storage system of your choice. It can be either local storage or a public cloud provider. ",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Automated Rollouts and Rollbacks",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "Our specialists ensure business continuity and flexibility with automated rollouts and rollbacks. We also continuously monitor your applications keeping your business running smoothly 24/7. ",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "Lower-Costs",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "With Kubernetes, our specialists can help you reduce total cost and ensure better performance with simpler resource management. ",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },
                                    {
                                        id: "5",
                                        title: "App Resiliency & Availability  ",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "With multi-cloud flexibility, our specialists help you boost the app resiliency and availability ensuring continuous operations.  ",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "6",
                                    //     title: "Application Containerization ",
                                    //     // subTitle: "",
                                    //     description: "We help you improve agility by developing and implementing a containerization strategy suited uniquely to your needs. We employ tools and frameworks such as Docker, Kubernetes, Red Hat OpenShift and public cloud containers to devise and implement the best DevOps strategy for your business.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "7",
                                    //     title: "Cloud Native Applications",
                                    //     // subTitle: "",
                                    //     description: "Our Cloud expertise allows us to co-create and deploy cloud native applications with modern microservice and dev-ops architecture. We help transfer skiils to your team to dramatically scale innovation and expertise across your organization.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "8",
                                    //     title: "CI/CD",
                                    //     // subTitle: "",
                                    //     description: "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. We help train your team, empowering you to stay ahead of the competition.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "9",
                                    //     title: "HashiCorp",
                                    //     // subTitle: "",
                                    //     description: "Leverage native Azure services to deliver powerful applications that are economical to run",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // }, {
                                    //     id: "10",
                                    //     title: "Data Migration",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "11",
                                    //     title: "AI Services",
                                    //     // subTitle: "",
                                    //     description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "12",
                                    //     title: "Blockchain Services",
                                    //     // subTitle: "",
                                    //     description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                {/* </Container> */}

            </div>
        );
    }
}


export default KubernetesServices;
