import React,{useEffect,useState} from 'react';
import './CookieCon.scss'
import { Modal } from "react-bootstrap";
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';




function CookieCon() {

  const [modalShow, setModalShow] = React.useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="cookieBannerDiv">
      <>
        <span onClick={() => setModalShow(true)}>Cookies</span>

        <Modal className={windowSize.width < 576 ? "cookieModal1":"cookieModal2"} show={modalShow} onHide={() => { setModalShow(false) }}
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              XivTech
        </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={2} style={{ paddingRight: "8px" }}>
                <Zoom><h6 className="cookieh6">Information We Collect</h6></Zoom>
              </Col>
              <Col lg={10} style={{ paddingLeft: "0px" }}>
                <Fade bottom>
                  <div className="cookieInnerDiv">
                    <h5>Privacy policy</h5>
                    <p>This privacy notice discloses the privacy practices for <a href="https://www.xivtech.io">XivTech.</a> This privacy notice applies solely to information collected by this website. It will notify you of the following:
                    <ul>
                        <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
                        <li>What choices are available to you regarding the use of your data.</li>
                        <li>The security procedures in place to protect the misuse of your information.</li>
                        <li>How you can correct any inaccuracies in the information.</li>
                      </ul>
                    </p>
                    <h5 style={{ marginBottom: "10px" }}>Information We Collect About You</h5>
                    <p>We are the sole owners of the information collected on this site. We only have access to collected information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
                    We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request.
Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
                    <h5>Your Access to and Control Over Information</h5>
                    <p>We are the sole owners of the information collected on this site. We only have access to collected information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
                    We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request.
Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
                    <h5>Security</h5>
                    <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
                    While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
If you feel that we are not abiding by this privacy policy, you should contact us immediately via <a href="mailto:contact@XivTech.io">contact@XivTech.io</a>
                    </p>
                  </div>
                </Fade>
              </Col>
            </Row>


          </Modal.Body>
        </Modal>
      </>
    </div>

  )
};

export default CookieCon;