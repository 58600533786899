import React, { PureComponent } from 'react';
import { Card, CardDeck, CardTitle, CardBody,CardImg, CardText, Row, Col} from 'reactstrap';
import './CapabilitiesList.scss';


class CapabilitiesList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }
      
      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
    render() {

        const {
            headerName,
            capabilitiesDescription,
            capabilities,
        } = this.props;

        return (
            <div className={this.state.width > 1200 ? "container1" : "container"}>
                <Row>
                    <Col md="12">
                        <div align="left">
                            <h1 className="card-list-header font-weight-bold " >
                                {headerName}
                            </h1>
                            <p>{capabilitiesDescription}</p>
                        </div>

                    </Col>
                </Row>
                <Row className="capabilities-list-card">
                    <CardDeck>
                        {
                            capabilities.map(capability => (
                                <Col xs="12" md="4" key={capability.id}>
                                    <Card className="capabilities-list-card">
                                    <CardImg className="cardImg" src={capability.img} alt="Card image cap" />
                                        <CardBody className="text-left ">
                                            <CardTitle className="capability-card-title font-weight-bold">{capability.title}</CardTitle>
                                            <hr />
                                            <CardText className="capability-card-description">{capability.description}</CardText>
                                            <ul>
                                                {capability.list.map(capabilityListItem => {
                                                    return (
                                                        <div>
                                                            <li>{capabilityListItem}</li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))

                        }
                    </CardDeck>

                </Row>
            </div>
        );
    }
}

export default CapabilitiesList;

CapabilitiesList.propTypes = {
    // array of shape 
    // /define later
    // serviceHeader: string.isRequired,
    // serviceDescription: string.isRequired,
    // asset: string.isRequired,
};
