import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import {PageTitle} from '../../../Base/components/'
import './SearchBar.scss';
import MiniSearch from '../MiniSearch/MiniSearch';

class SearchBar extends PureComponent {
     constructor(props) {
         super(props)
     
         this.state = {
              show:false
         }
     }
     
    render() {
        return (
            <div className="searchDiv">
                        <FontAwesomeIcon className={this.props.class} icon={this.state.show ? faTimes : faSearch} onClick={()=>{
                             this.setState(prevState => ({
                                show: !prevState.show,
                            }));
                        }} />
                      {
                          this.state.show&&
                          <div className="searchInnerDiv">
                              <MiniSearch />
                          </div>

                      }  
            </div>

        );
    }
}

export default SearchBar;
