import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CardListIndex,ServicesHero, ServicesAction } from '../../../../Base/components'
import {  cicdServiceUrl} from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './DeliveryPipelineAutomationHome.scss';


class DeliveryPipelineAutomationHome extends PureComponent {

    render() {
        const breadcrumbs = [
            // { children: "Services/", href: servicesUrl() },
            { children: "DevOps Services" },
        ]

        const serviceActionDescription = `We help you get to market quicker by integrating DevOps Automation leveraging Containers, CICD and Microservices.
        Achieve business outcomes by adopting DevOps. Our unique capabilities allow us to help you build continuous delivery pipeline, enhance reliability & resilience, balance agility & consistency at scale.
        
        `


        return (
            <div>
                <PageTitle text={"DevOps with Azure"} description={"Automate the continuous deployment of your project with Azure DevOps at XivTech.  "} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="DevOps with Azure"
                    description="Greater Agility, Resilience and Portability by building and deploying apps rapidly."
                />


                <ServicesAction
                    title1 = "Collaborate"
                    title2 = "Speed"
                    title3 = "Innovate"
                    description={serviceActionDescription}
                    classForGT="deliveryPipeline"
                />

                <Container>


                    <Row>
                        <Col>

                            <CardListIndex
                                headerName={"Capabilities"}
                                cards={[
                                    {
                                        title: "Continuous Integration and Continuous Deployment",
                                        // titleSubtitle: "ci cd",
                                        titleText: "Build, Integration and Testing process automation",
                                        asset: "/tile1.png",
                                        id: "1",
                                        to: cicdServiceUrl()
                                    },
                                    // {
                                    //     title: "Terraform Services",
                                    //     titleSubtitle: "Automation, Optimization, Orchestration and Scale",
                                    //     titleText: "",
                                    //     asset: "/tile2.png",
                                    //     id: "2",
                                    //     to: terraformServiceUrl()
                                    // },
                                    // {
                                    //     title: "Container Services",
                                    //     titleSubtitle: "Greater Reliability, Portability and Consistency",
                                    //     // titleText: "Container Services",
                                    //     asset: "/tile3.png",
                                    //     id: "3",
                                    //     to: containerServiceUrl()
                                    // }
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}


export default DeliveryPipelineAutomationHome;
