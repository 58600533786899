import React, { PureComponent } from 'react';
import { Jumbotron, Container, Row, Col,Card, CardImg } from 'reactstrap';
import { string } from 'prop-types';
import './TeaserTile.scss';

class TeaserTile extends PureComponent {

    render() {

        const {
            serviceHeader,
            serviceDescription,
            serviceList,
            asset
        } = this.props;

        return (
            <Jumbotron fluid >
                <Container fluid >

                    <Row>
                        <Col md="6">
                            <Card>
                                <CardImg top width="1000%" src={asset} alt="Card image cap" />
                            </Card>
                            {/* <div className="col-md-6">
                                <img src={asset} alt="not found" />
                            </div> */}
                        </Col>
                        <Col md="6" >
                            <div >
                                <h1 className="display-5" align="left">{serviceHeader}</h1>
                                {
                                    serviceDescription &&
                                    serviceDescription.map(description => (
                                        <p className="lead" align="left">
                                            {description}
                                        </p>
                                    ))
                                }
                                {
                                    serviceList &&
                                    <ul>
                                        {
                                            serviceList.map(service => (
                                                < li >
                                                    {service}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                }


                            </div>
                        </Col>

                    </Row>

                </Container>
            </Jumbotron>
        );
    }
}

export default TeaserTile;

TeaserTile.propTypes = {
    serviceHeader: string.isRequired,
    serviceDescription: string.isRequired,
    asset: string.isRequired,
};

//take this out to type
// serviceList not required