import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,TempPara } from '../../../Base/components/'
import { rootUrl } from '../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';
import { Suspense } from 'react';
import './AboutIndex.scss';
import SolvingTogether from '../../../Base/components/SolvingTogether/SolvingTogether';
import ReadyToStart from '../../../Base/components/ReadyToStart/ReadyToStart';
// import SiteMap from '../../../Base/components/SiteMap/SiteMap';

const OurOffices =  React.lazy(() => {return import("../../../Base/components/OurOffices/OurOffices")});

class AboutIndex extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            { children: 'About' }
        ]

        return (
            <div>
                <PageTitle text={"Customer Focussed Digital Transformation"} description={"At XivTech, we enable the digital transformation journey for our customers by strategizing around unique customer needs and offering expert solutions."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <Container style={{marginLeft:"1.9%",marginTop:"-5%"}} className="about-container">
                    <div className="about-text">
                        <div>
                            <h3>About XivTech:</h3>
                            <p>XivTech is an AI/ML powered RPA automation company with a collaborative flagship product offering – EKISU that helps you automate and streamline your workflows to function efficiently.</p>
                        </div>
                        <div>
                            <h3>Our Vision:</h3>
                            <p>We envision to assist our clients to realize the vision of future with productized RPA automation.</p>
                        </div>
                        <div>
                            <h3>What we offer:</h3>
                            <p>We provide innovative and cost-effective automated digital solutions.</p>
                            <p>Our core focus on AI/ML + RPA, Design Thinking and Customer Experience allows us to lead customer focused innovation, transforming businesses for growth, continued success and leadership.</p>
                        </div>
                        <div>
                            <h3>What does it mean for you:</h3>
                            <ul>
                                <li>Digital transformation for your business to grow</li>
                                <li>Boost productivity and champion success</li>
                                <li>Future-proofing your business</li>
                                <li>Help you resolve your multifaceted problems using AI/ML powered RPA</li>
                                <li>Automate your workflows, optimize efficiency, and increase profitability</li>
                            </ul>
                        </div>
                        <p>We at XivTech are committed and driven to deliver on customer expectations.</p>
                        <h3>Core Values of XivTech:</h3>
                        <ul>
                            <li>Say it as it is</li>
                            <li>Focus</li>
                            <li>Collaborative</li>
                            <li>Trustworthy</li>
                            <li>Innovative</li>
                            <li>Competent</li>
                        </ul>
                    </div>
                    {/* <Row>
                        <Col>
                            <TempPara 
                                paragraph={[
                                    {
                                        header: "Customer-Focussed Digital Transformation",
                                        subheader: "We partner with our clients to help them realize the vision of the future",
                                        description: "At XivTech, we enable the digital transformation journey for our customers by strategizing around unique customer needs and offering expert solutions. Our core focus on design thinking, customer experience and cutting edge technology allows us to help drive customer focussed innovation, transforming businesses for continued success and leadership.",
                                        items: [
                                            // "para1 hello1",
                                            // "para1 hello2"
                                        ],
                                    },
                                    // {
                                        // header: "About 222us header",
                                        // subheader: "Hello this is the subheader",
                                        // description: "this is he description para1. blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah ",
                                        // items: [
                                            // "para1 hello1",
                                            // "para1 hello2"
                                        // ],
                                    // },
                                    // {
                                    //     header: "",
                                    //     subheader: "",
                                    //     description: " ",
                                    //     items: [
                                    //         "",
                                    //         ""
                                    //     ],
                                    // },
                                ]}
                            />
                        </Col>
                    </Row> */}

                </Container>
              {/* <SolvingTogether /> */}
              <ReadyToStart /> 
              <Suspense fallback={<h1>Loading…</h1>}>
                   <OurOffices />
              </Suspense>
              {/* <SiteMap /> */}
            </div>
        );
    }
}


export default AboutIndex;
