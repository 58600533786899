import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';

import './TalkToExpertButton.scss';

class TalkToExpertButton extends PureComponent {

    render() {

        const {
            to,
        } = this.props;

        return (
            <Button
                className="talk-to-expert-btn talk-to-expert-btn:hover"
                size="sm"
                to={to}
                tag={Link}
            >
                Talk to an expert
            </Button>
        );
    }
}


export default TalkToExpertButton;


TalkToExpertButton.propTypes = {
    to: string.isRequired,
};