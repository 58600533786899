import React, { Component } from 'react'
import "./Services.scss"
import { Row, Col, Collapse, Button, } from 'reactstrap';
import ServiceSearchItem from '../ServiceSearchItem/ServiceSearchItem';
import ContactForm from '../ContactForm/ContactForm';

class Services extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        const strategies = [
            {
                title: "Growth Strategy",
                link: "",
                text: "Envision your future, then define and de-risk your path to getting there by taking a creative approach to strategy."
            },
            {
                title: "Innovation Strategy",
                link: "",
                text: "Ignite innovation without disrupting your core business by creating scalable strategies to help you seize the moment and bring market-changing ideas to life."
            },
            {
                title: "Commerce in a Digital World",
                link: "",
                text: "Develop innovative omnichannel commerce and direct-to-customer programs by putting the customer first, leveraging data and designing unparalleled experiences."
            },
        ]

        const businesses = [
            {
                title: "Startup Growth",
                link: "",
                text: "Integrate design, strategy and delivery to bend the curve of startup growth and make your vision a market-changing reality."
            },
            {
                title: "Corporate Venture Building",
                link: "",
                text: "Rapidly build new businesses to capitalize on new markets, technologies and behaviors."
            },
        ]

        const experiences = [
            {
                title: "CX Strategy, Design & Performance",
                link: "",
                text: "Experiential design thinking, behavioral science and performance tracking allow you to envision, build and measure differentiated customer and employee experiences for today and tomorrow."
            },
            {
                title: "Product Design & Build",
                link: "",
                text: "There’s no need to choose between getting it built fast and getting it built right with our adaptive, human-centered approach to product design and delivery."
            },
            {
                title: "Service Design",
                link: "",
                text: "Use human-centered design and research to understand your customers and create delightful, differentiated services to keep them engaged."
            },
            {
                title: "Customer Research & Insights",
                link: "",
                text: "Create virtuous cycles of design and development, infused with customer feedback at every stage to ensure you’re keeping the customer at the center of everything you do."
            },
        ]

        const activations = [
            {
                title: "CX Strategy, Design & Performance",
                link: "",
                text: "Experiential design thinking, behavioral science and performance tracking allow you to envision, build and measure differentiated customer and employee experiences for today and tomorrow."
            },
            {
                title: "Product Design & Build",
                link: "",
                text: "There’s no need to choose between getting it built fast and getting it built right with our adaptive, human-centered approach to product design and delivery."
            },
            {
                title: "Service Design",
                link: "",
                text: "Use human-centered design and research to understand your customers and create delightful, differentiated services to keep them engaged."
            },
            {
                title: "Customer Research & Insights",
                link: "",
                text: "Create virtuous cycles of design and development, infused with customer feedback at every stage to ensure you’re keeping the customer at the center of everything you do."
            },
        ]
        return (
            <div className="servicesMainContainer">
                <div className="firstContainer">
                    <p>Services</p>
                    <h1>Craft experiences that touch hearts and move markets</h1>
                    <h4>Meet the moment with frog’s expanded services and capabilities that help you define your vision for the future and make it happen.</h4>
                </div>
                <div className="serviceImage">
                    <img src="s" alt="" />
                </div>
                <div className="secondContainer">
                    <p>Business</p>
                    <Row>
                        <Col md={{ size: 6 }} className="pr-5 mt-3">
                            <h3>Make Bolder Choices</h3>
                            <h5>Craft-focused strategies to realize market-changing ideas.</h5>
                            {
                                strategies.map((strategy) => {
                                    return (

                                        <ServiceSearchItem title={strategy.title} text={strategy.text} link={strategy.link} />
                                    )
                                })
                            }
                        </Col>
                        <Col md={{ size: 6 }} className="mt-3">
                            <h3>Launch New Businesses</h3>
                            <h5>Build ventures that address emerging behaviors, markets and technologies.</h5>
                            {
                                businesses.map((business) => {
                                    return (

                                        <ServiceSearchItem title={business.title} text={business.text} link={business.link} />
                                    )
                                })
                            }
                        </Col>
                    </Row>


                </div>
                <div className="serviceImage">
                    <img src="https://" alt="" />
                </div>
                <div className="secondContainer">

                    <p>Brand</p>
                    <Row>
                        <Col md={5} className="mt-3">
                            <h3>Drive Circular Purpose</h3>
                            <h5>Lead your business toward a long-term vision for holistic prosperity.</h5>
                            {
                                strategies.map((strategy) => {
                                    return (

                                        <ServiceSearchItem title={strategy.title} text={strategy.text} link={strategy.link} />
                                    )
                                })
                            }
                        </Col>
                        <Col md={{ size: 6, offset: 1 }} className="pr-5 mt-3">
                            <h3>Craft Unique Stories </h3>
                            <h5>Build ventures that address emerging behaviors, markets and technologies.</h5>
                            {
                                businesses.map((business) => {
                                    return (

                                        <ServiceSearchItem title={business.title} text={business.text} link={business.link} />
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>

                <div className="serviceImage">
                    <img src="" alt="" />
                </div>

                <div className="secondContainer">

                    <p>Experience</p>
                    <Row>
                        <Col md={6}>
                            <h3>Build Better Products & Experiences</h3>
                            <h5>Design and build products, services and experiences that your customers will love.</h5>
                            
                        </Col>
                        <Col md={6} className="mt-3">
                        {
                                experiences.map((experience) => {
                                    return (

                                        <ServiceSearchItem title={experience.title} text={experience.text} link={experience.link} />
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>

                <div className="serviceImage">
                    <img src="https://" alt="" />
                </div>


                <div className="secondContainer">

                    <p>Activation</p>
                    <Row>
                        <Col md={6}>
                            <h3>Nurture Lasting Relationships</h3>
                            <h5>Cultivate engagement through insightful content that speaks to customer needs.</h5>
                           
                        </Col>
                        <Col md={6} className="mt-3">
                        {
                                activations.map((activation) => {
                                    return (

                                        <ServiceSearchItem title={activation.title} text={activation.text} link={activation.link} />
                                    )
                                })
                            }
                        </Col>

                    </Row>
                </div>

                <div className="serviceImage">
                    <img src="https://" alt="" />
                </div>
                <div className="secondContainer">

                    <p>Enablement</p>
                    <Row>
                        <Col md={5} className="pr-5 mt-3" sm={12}>
                            <h3>Form Stronger Teams </h3>
                            <h5>Grow critical design capabilities and a culture of innovation from within your organization.</h5>
                            {
                                strategies.map((strategy) => {
                                    return (

                                        <ServiceSearchItem title={strategy.title} text={strategy.text} link={strategy.link} />
                                    )
                                })
                            }
                        </Col>
                        <Col md={{ size: 6, offset: 1 }} sm={12} className="mt-3">
                            <h3>Master Scalable Operations</h3>
                            <h5>Design and build customer-focused organizations, processes and platforms for the future.</h5>
                            {
                                businesses.map((business) => {
                                    return (

                                        <ServiceSearchItem title={business.title} text={business.text} link={business.link} />
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                 <div className="firstContainer">
               <ContactForm />
                </div>
               <div className="line"></div>
            </div>
        )
    }
}


export default Services;