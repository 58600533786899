import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList, ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl,  deliveryPipelineAutomationUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './CICDServices.scss';


class CICDServices extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'DevOps Services', href: deliveryPipelineAutomationUrl() },
            { children: 'CICD Services' }
        ]

        const serviceActionDescription = `Our expertise with continuous integration, and continuous delivery (CI/CD) helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and reliability. We train your team and empower you to stay ahead of the competition.        `

        return (
            <div>
                <PageTitle text={"CI/CD with Azure Devops"} description={"XivTech’s delivery pipeline automation service powers end-to-end CI/CD pipeline, DevOps pipeline workflows across your teams to help automate your application releases faster."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="CI/CD with Azure DevOps"
                    description="Quality Software with Repeatable and Reliable deployments"
                />

                <ServicesAction
                    title1=  "Quality"
                    title2 = "Reliability"
                    title3 = "Speed"
                    description={serviceActionDescription}
                    classForGT="CICD"
                />

                {/* <Container> */}
                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits of CI/CD."}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "DevOps Advisory Services",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "Our DevOps advisory experts will work with you to devise the best DevOps strategy, tailored to your unique needs. Our expertise and focussed approach allows us to establish and optimize the CI/CD development model suited to achieve your business objectives.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "App Pipelines",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "We help you ship quality software faster by establishing a CI/CD workflow, application & infrastructure deployment. It allows for feature based releases, allowing for Faster Time to Market cycles.",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Serverless Infrastructure",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "We help you deploy applications using CI/CD container pipelines, use managed Kubernetes services for your preferred cloud platform.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "4",
                                    //     title: "Serverless Infrastructure",
                                    //     // subTitle: "",
                                    //     description: "We help you deploy applications using CI/CD container pipelines, use managed Kubernetes services for your preferred cloud platform.",
                                    //     list: [

                                    //         // "Application Modernization",
                                    //         // "Data Modernization",
                                    //     ],
                                    // },
                                    {
                                        id: "5",
                                        title: "Process Automation",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "We help you with Build, Integration and Testing Process Automation. It allows for transparency in the development cycle, saving valuable time and bringing in speed.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "6",
                                    //     title: "Application Containerization ",
                                    //     // subTitle: "",
                                    //     description: "We help you improve agility by developing and implementing a containerization strategy suited uniquely to your needs. We employ tools and frameworks such as Docker, Kubernetes, Red Hat OpenShift and public cloud containers to devise and implement the best DevOps strategy for your business.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "7",
                                    //     title: "Cloud Native Applications",
                                    //     // subTitle: "",
                                    //     description: "Our Cloud expertise allows us to co-create and deploy cloud native applications with modern microservice and dev-ops architecture. We help transfer skiils to your team to dramatically scale innovation and expertise across your organization.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "8",
                                    //     title: "CI/CD",
                                    //     // subTitle: "",
                                    //     description: "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. We help train your team, empowering you to stay ahead of the competition.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "9",
                                    //     title: "HashiCorp",
                                    //     // subTitle: "",
                                    //     description: "Leverage native Azure services to deliver powerful applications that are economical to run",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // }, {
                                    //     id: "10",
                                    //     title: "Data Migration",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "11",
                                    //     title: "AI Services",
                                    //     // subTitle: "",
                                    //     description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "12",
                                    //     title: "Blockchain Services",
                                    //     // subTitle: "",
                                    //     description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                {/* </Container> */}

            </div>
        );
    }
}


export default CICDServices;
