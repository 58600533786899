import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import "./Capabilities.scss"


class Capabilities extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="">
                   {this.props.direction == "right" &&

<div className="webDiv">
    <div className="capabilities_container">

    <Row>
        <Col md={{ size: 5, offset: 1 }}>
            <div className="capabilitiesLeft">
                <h3>{this.props.title}</h3>
                <p>{this.props.text}</p>
                <div className="innerImgContainer">
                    <img className="arrowImg" src="/Arrow-black.svg" alt="" /><span><a href="">{this.props.titleLink}</a></span>
                </div>
            </div>
        </Col>

        <Col md={{ size: 5, offset: 1 }}>
            <div className="capabilitiesRight">
                <img src={this.props.imgLink} alt="" />
            </div>
        </Col>
    </Row>
    </div>
</div>
}
                <div className="capabilities_container">
                    {this.props.direction == "left" &&
                        <Row>
                            <Col md={{ size: 5, offset: 1 }}>
                                <div className="capabilities_left">
                                    <img src={this.props.imgLink} alt="" />
                                </div>
                            </Col>

                            <Col md={{ size: 5, offset: 1 }}>
                                <div className="capabilities_right">
                                    <h3>{this.props.title}</h3>
                                    <p>{this.props.text}</p>
                                    <div className="innerImgContainer">
                                        <img className="arrowImg" src="/Arrow-black.svg" alt="" /><span><a href="">{this.props.titleLink}</a></span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        )
    }
}


export default Capabilities;