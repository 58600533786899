import React, { Component } from 'react'
import {
    Card, Button, CardImg, CardTitle, CardText, CardGroup,
    CardSubtitle, CardBody, Row, Col
} from 'reactstrap';
import './AnimateDesign.scss'
import Fade from 'react-reveal/Fade';

class AnimateDesign extends Component {
    render() {
        return (
            <div className="mainDesignDiv">
                <div className="topDesign">
                    <img src="/x1.jpg" alt="" />
                </div>
                <div className="midDesign">
                    <div className="innerMidDiv">
                        <div className="liveText">
                            <h5>Our Proven Process</h5>
                            <Row>
                                <Col md="7">
                                    <div className="leftAnimation">
                                        <p className="leftP1">Align</p>
                                        <p className="leftP2">Design</p>
                                        <p className="leftP3">Refine</p>
                                    </div>
                                </Col>
                                <Col md="5">
                                    <div className="rightAnimationText">
                                        <p className="rightP1">
                                            <Fade bottom>
                                            We Productize services. With Ekisu - our flagship AI automation product, we lead the RPA transformation process to strategize, design, build, launch and support the automation. Following deploy, we follow a feedback based refinement process.
                                            </Fade>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <p className="heading2">Our Expertise</p>
                        <Fade bottom>
                           <h3>Our core focus on AI + RPA, Design Thinking and Customer Experience allows us to help drive customer focussed innovation. We automate businesses for growth, continued success and leadership.</h3>
                        </Fade>
                        <div className="cardsDiv">
                            <CardGroup>
                                <Card className="mr-5 cardSpecific">
                                    <CardImg top width="100%" src="/x2.jpg" alt="Card image cap" />
                                    <CardBody className="m-0">
                                        <CardTitle tag="h4">Strategy</CardTitle>
                                        <Fade bottom>
                                            <CardText className="cardText">

                                                <p>Digital Transformation</p>
                                                <p>AI + RPA Automation</p>
                                                <p>Operational Model</p>
                                                <p>Product Vision</p>
                                                <p>Product Roadmap</p>
                                            </CardText></Fade>
                                    </CardBody>
                                </Card>
                                <Card className="mr-5 cardSpecific">
                                    <CardImg top width="10%" src="/x3.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardTitle tag="h4">Design + Production</CardTitle>
                                        <Fade bottom>
                                            <CardText className="cardText">

                                                <p>RPA automation </p>
                                                <p>Machine Learning / AI</p>
                                                <p>Document Data Capture</p>
                                                <p>Bot Deploy and Scale</p>
                                                <p>Web/Mobile App Development</p>
                                            </CardText></Fade>
                                    </CardBody>
                                </Card>
                                <Card className="mr-5 cardSpecific">
                                    <CardImg top width="100%" src="/x4.jpg" alt="Card image cap" />
                                    <CardBody>
                                        <CardTitle tag="h4">Growth</CardTitle>
                                        <Fade bottom>

                                            <CardText className="cardText">

                                                <p>SaaS Platform</p>
                                                <p>Bot Optimization</p>
                                                <p>AI + RPA Lifecycle Management</p>
                                                <p>A/B Testing</p>
                                                <p>KPI Tracking</p>

                                            </CardText></Fade>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </div>
                    </div>
                </div>
                <div className="bottomDesign">
                    <img src="https://mk0northstreetcpdixa.kinstacdn.com/wp-content/themes/northstreet-theme-v4/img/text-or-video/expertise/img_expertise-02.jpg" alt="" />
                </div>
            </div>
        )
    }
}


export default AnimateDesign;