import React, { PureComponent } from 'react';
import { Card, CardTitle,  CardImg, CardImgOverlay } from "reactstrap";
import { TitleLink } from "../";

import './ServiceItemImage.scss';

class ServiceItemImage extends PureComponent {


    render() {
        const {
            serviceItemTitle,
            asset,
            to
        } = this.props;


        return (

            <div>
                <Card inverse >
                    <CardImg src={asset} alt="Card image cap" />
                    <CardImgOverlay>
                        <CardTitle className="service-item-title" >
                            <TitleLink
                                className="service-item-title-link"
                                titleName={serviceItemTitle}
                                to={to}
                            />
                        </CardTitle>
                    </CardImgOverlay>
                </Card>
            </div>


        );
    }
}

export default ServiceItemImage;
