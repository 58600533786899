import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList, ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl, simplifyCloudOpsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';


class rpa1 extends PureComponent {

    render() {
        const breadcrumbs = [
            // { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            // { children: 'Simplify Cloud Ops', href: simplifyCloudOpsUrl() },
            // { children: 'Multi-Cloud Flexibility' }
        ]

        const serviceActionDescription = `Our team of professionals help you deliver measurable business outcomes by strategizing, designing and executing results driven AI + RPA automation.
        With Ekisu - our flagship AI  automation product, we help you with end-to end automation for your workflows. Each automation helps you save time, reduce cost of human capital, and improve efficiency! Now that’s successful Digital Transformation!`

        return (
            <div>
                <PageTitle text={"AI + RPA = Enabling End-to-End Automation"} description={"Helps to Build, Deliver, and Scale Containerized Apps at XivTech. Learn More! Portable. Highly Secure. Scalable. Extensible. Services: Ship Promptly, Operate with Ease."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs}/>
                <ServicesHero
                    asset={"/main-servce1.jpeg"}
                    header="AI + RPA = Enabling End-to-End Automation"
                    description="Drive Efficiency, Automate and Monetize"
                />

                <ServicesAction
                    title1 = "Process "
                    title2 = " Automation"
                    title3 = "Monetization"
                    description={serviceActionDescription}
                    classForGT="kubernetesServices"
                />

                {/* <Container> */}


                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits of Kubernetes Services"}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "AI + RPA = Enabling End-to-End Automation",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "At Xivtech, with Kubernetes and containers we help you realize the promise of hybrid and multi-cloud. Our specialists craft specs for your business to avoid vendor lock-in.  ",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "1",
                                        title: "Build, Deploy and Scale bots",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "At Xivtech, with Kubernetes and containers we help you realize the promise of hybrid and multi-cloud. Our specialists craft specs for your business to avoid vendor lock-in.  ",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },

                                ]}
                            />
                        </Col>
                    </Row>
            </div>
        );
    }
}


export default rpa1;
