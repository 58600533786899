import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList, ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl,  enterpriseAppsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './ModernizeApps.scss';


class ModernizeApps extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'EnterpriseApps', href: enterpriseAppsUrl() },
            { children: 'ModernizeApps Services' }
        ]

        const serviceActionDescription =
            // "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. " +
            // "We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. " +
            // "We help train your team, empowering you to stay ahead of the competition.";
            `The aging infrastructure can minimize the business opportunities and we can help you stay compatible by enabling your business access to agile applications.  Our specialists will offer end-to-end application modernization solutions to help you reduce excessive cost, make your business cloud-enabled and reform the legacy apps to modern agile platform.
            Modernization of the apps will give you the flexibility with your valuable time to grow your business rapidly, while we manage the underline infrastructure.  We apply the best techniques and architectural approaches to maintain the collective portfolio of your existing and modern applications. 
            `

        return (
            <div>
                <PageTitle text={"Rearchitect Valuable Legacy Apps"} description={"Partner with XivTech, modernize your legacy apps, grow your business rapidly, understand the real promise of application modernization: delivering differentiated business value."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Rearchitect Valuable Legacy Apps"
                    description="Modernize Legacy Apps, Align with the Latest Trends, Drive Business Value."
                />

                <ServicesAction
                    title1=  "Newer"
                    title2 = "Faster"
                    title3 = "Sleeker"
                    description={serviceActionDescription}
                    classForGT="modernizeApps"
                />

                {/* <Container> */}
                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits of Modern Apps."}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Modernize Legacy Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "Make the applications that matter more reliable, accessible, and scalable. Our specialists will help your business move to the cloud native services, eliminate redundancies and enhance productivity.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "Re-Architect & Rebuild Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "We deliver value through creative problem solving. We will evaluate your existing application to offer the best solutions with minimal risk and maximum business opportunities.",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Redesign Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "We help deliver a world-class experience to your customers through the application of Design Thinking. Through a human-centric design methodology, our designers deliver creative solutions that will resonate with your target audience while advancing your business objectives.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "Replatform Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-2/summarytile/parsys.coreimg.png/1579165114555.png",
                                        // subTitle: "",
                                        description: "Upgrade your application from its existing platform to run on the cloud while adhering to the minimum possible Twelve factors, preserving existing functionality. It may return only the basic cloud benefits, but it may be perfectly suited for your business needs.",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },
                                    {
                                        id: "5",
                                        title: "Lift & Shift: Rehost Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-1/summarytile_copy_cop/parsys.coreimg.png/1579165126972.png",
                                        // subTitle: "",
                                        description: "Migrate your app to the cloud with minimal or no change to the code and transition it from on-prem to the cloud. It allows for cost-savings, cloud disaster recovery and reduces technical-debt.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "6",
                                        title: "Refactor: Monolith to Microservice",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "We help you adopt micro-services architecture and boost velocity. It involves rapid iteration and small changes to discrete components of a system. Deploying small changes frequently results in big benefits over time.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "7",
                                        title: "Improve Business Agility & Productivity",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-1/summarytile_copy_cop/parsys.coreimg.png/1579165126972.png",
                                        // subTitle: "",
                                        description: "We help you to accelerate innovation, reduce costs and improve customer experiences. We start by understanding what you are solving for and delivering continuous application modernization across leading technologies.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "8",
                                    //     title: "CI/CD",
                                    //     // subTitle: "",
                                    //     description: "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. We help train your team, empowering you to stay ahead of the competition.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "9",
                                    //     title: "HashiCorp",
                                    //     // subTitle: "",
                                    //     description: "Leverage native Azure services to deliver powerful applications that are economical to run",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // }, {
                                    //     id: "10",
                                    //     title: "Data Migration",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "11",
                                    //     title: "AI Services",
                                    //     // subTitle: "",
                                    //     description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "12",
                                    //     title: "Blockchain Services",
                                    //     // subTitle: "",
                                    //     description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                {/* </Container> */}

            </div>
        );
    }
}


export default ModernizeApps;
