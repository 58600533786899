import React, { PureComponent } from 'react';

import './InsightsIndex.scss';


class InsightsIndex extends PureComponent {

    render() {
        return (
            <div>
                <p>
                InsightsIndex Page Welcome
                </p>

            </div>
        );
    }
}


export default InsightsIndex;
