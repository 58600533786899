import React, { PureComponent } from 'react';
import { Card, CardImg, CardTitle, CardBody, CardSubtitle, CardText } from 'reactstrap';
import { cardType } from '../../types'
import './CardIndex.scss';

class CardIndex extends PureComponent {

    constructor(props) {
        super(props);

        this.onCardClick = this.onCardClick.bind(this);
    }

    async onCardClick(to) {
        console.log(this.props.to);
        // TODO
        // if need be for Link then use this callback
    
    }

    render() {

        const {
            title,
            titleSubtitle,
            titleText,
            asset,
            to,
        } = this.props;



        return (
            <div className="mt-4">
                <a style={{ cursor: 'pointer' }} onClick={this.onCardClick} href={to}>
                    <Card >
                        <CardImg top width="100%" src={asset} alt="Card image cap" />
                        <CardBody>
                            <CardTitle>{title}</CardTitle>
                            <CardSubtitle>{titleSubtitle}</CardSubtitle>
                            <CardText>{titleText}</CardText>
                        </CardBody>
                    </Card>
                </a>
            </div>

        );
    }
}

export default CardIndex;

CardIndex.propTypes = {
    ...cardType.isRequired,
};

// TODO check if the above is fine
// add to to the type