import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,  CapabilitiesList,ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl, simplifyCloudOpsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './ServiceMeshServices.scss';


class ServiceMeshServices extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'Simplify Cloud Ops', href: simplifyCloudOpsUrl() },
            { children: 'ServiceMesh Services ' }
        ]

        const serviceActionDescription =
            "We enable the cloud journey for our customers by strategizing around unique customer needs and offering " +
            "expert solutions. Our core focus on rationalization allows us to unlock the true value of the cloud, by framing tailored and " +
            "risk-mitigated cloud strategy solutions which consider all possibilities and minimal downtime.";

        return (
            <div>
                <PageTitle text={"ServiceMesh Services"} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />
                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="ServiceMesh Services"
                    description="Greater Agility, Resilience and Portability by building and deploying apps rapidly."
                />

                <ServicesAction
                    title="ServiceMesh: Strategy, Design, and Implementation"
                    description={serviceActionDescription}
                />

                <Container>


                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits KubernetesServices"}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Cloud First Advisory",
                                        // subTitle: "",
                                        description: "Our Azure advisory experts will work with you to devise the best Cloud First Digital Transformation Strategy, tailored to your unique needs. Our focussed business outcome framework allows us to clearly help you identify business outcomes to ensure a successful cloud transformation.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "Istio",
                                        // subTitle: "",
                                        description: "Our unique rationalization approach allows us to Rehost, Refactor, Rearchitect, Rebuild or Replace applications to a modern microservice architecture.",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Consul",
                                        // subTitle: "",
                                        description: "We help you execute your cloud strategy by helping you move to the cloud while keeping your business moving. We rationalize your application portfolio and help migrate you to your preferred cloud offering.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "SPIRE/SPIFFE",
                                        // subTitle: "",
                                        description: "We specialize in architectural design, that accommodates hybrid private and public cloud strategies enabling you to future proof your digital transformation. Our expertise allows for a true hybrid cloud experience that increases efficiency, flexibility and productivity.",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },

                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                </Container>

            </div>
        );
    }
}


export default ServiceMeshServices;
