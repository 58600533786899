import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,TempPara } from '../../../Base/components/'
import { rootUrl } from '../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';
import './CareersIndex.scss';
import WorkWithUs from '../../../Base/components/WorkWithUs/WorkWithUs';


class CareersIndex extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            { children: 'Careers' }
        ]

        return (
            <div >
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />
            <div className="containerr">
                <PageTitle text={"Welcome to XivTech"} description={"XivTech is a Great Place to Work.. Are you obsessed with customer-focussed technology innovation? Explore a career with us!"} />


                 <Container >
                    <Row>
                        <Col>
                        <div className="careerHeading">
                        <TempPara 
                                paragraph={[
                                    {
                                        header: "Welcome to XivTech",
                                        subheader: "Are you obsessed with customer-focussed technology innovation?",
                                        description: "Explore a career with us..!",
                                        items: [
                                            "Join us by emailing your resume to careers@xivtech.io",
                                        ],
                                    },
                                    
                                ]}
                                />
                        </div>
                           
                            <WorkWithUs />
                        </Col>
                    </Row>

                </Container>
              </div>
            </div>
        );
    }
}


export default CareersIndex;
