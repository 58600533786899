import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,  CardListIndex,ServicesHero, ServicesAction } from '../../../../Base/components'
import { kubernetesServiceUrl} from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './SimplifyCloudOps.scss';
import Stats from '../../../../Base/components/Stats/Stats';


class SimplifyCloudOps extends PureComponent {

    render() {
        const breadcrumbs = [
            // { children: "Services/", href: servicesUrl() },
            { children: "SimplifyCloudOps Services" },
        ]

        const serviceActionDescription = `At XivTech, we make apps achieve agility and speed by making everything available on the cloud. With a focused CloudOps strategy, your business can achieve cost-effective operations, security, scalability, and flexibility.`


        return (
            <div>
                <PageTitle text={"Simplify-Cloud-Ops"} description={"XivTech’s simplify cloud ops service provides cloud management services for your business. Find out about our services; Kubernetes and much more. Let's talk."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Simplify Cloud Ops"
                    description="Deploy Frequently, Efficiently and Reliably"
                    
                />


                <ServicesAction
                    title1 = "Develop"
                    title2 = "Collaborate"
                    title3 = "Automate"
                    description={serviceActionDescription}
                    classForGT="simplifyCloudOps"
                />

                {/* <Stats /> */}
                <Container>

                    <Row>
                        <Col>

                            <CardListIndex
                                headerName={"Capabilities"}
                                cards={[
                                    {
                                        title: "Kubernetes Services  Automation, Optimization, Orchestration and Scale",
                                        // titleSubtitle: "ci cd",
                                        // titleText: "Automation, Optimization, Orchestration and Scale",
                                        asset: "/tile1.png",
                                        id: "1",
                                        to: kubernetesServiceUrl()
                                    },
                                    // {
                                    //     title: "ServiceMesh Services",
                                    //     titleSubtitle: "Greater Reliability, Portability and Consistency",
                                    //     // titleText: "Container Services",
                                    //     asset: "/tile2.png",
                                    //     id: "2",
                                    //     to: serviceMeshServiceUrl()
                                    // },

                                ]}
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}


export default SimplifyCloudOps;
