import React, {useEffect } from 'react';
import { Route, Switch,useLocation } from 'react-router-dom';
import { ServicesLayout } from '../../Offerings/services-index'
import { ContactIndex, InsightsIndex, CareersIndex, AboutIndex } from '../../Home/'
import { rootUrl, aboutUrl, careersUrl, insightsUrl,jobDescriptionUrl, servicesUrl, contactUrl, siteMapUrl } from "../../Base/helpers/url";
import { HomeLayout } from '../../Home'
import { Header, Footer } from './';
import { NotFound } from '../../Base/components';
import SiteMap from '../../Base/components/SiteMap/SiteMap';
import jobDescription from '../../Base/components/jodDescription/jobDescription';
import ReactGA from "react-ga";


function usePageViews() {
	let location = useLocation();
	useEffect(() => {
        ReactGA.initialize(`${process.env.REACT_APP_GA}`)
        ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	}, [location]);
}

function PrimaryLayout() {
        usePageViews();
        return (
            <div>
                <div>
                    <Header />
                    <Switch>
                        <Route
                            exact
                            path={rootUrl()}
                            component={HomeLayout}
                        />
                        <Route
                            exact
                            path={contactUrl()}
                            component={ContactIndex}
                        />
                        <Route
                            exact
                            path={insightsUrl()}
                            component={InsightsIndex}
                        />
                        <Route
                            exact
                            path={aboutUrl()}
                            component={AboutIndex}
                        />
                        <Route
                           // exact
                            path={careersUrl()}
                            component={CareersIndex}
                        />
                        <Route
                            //exact
                            path={servicesUrl()}
                            component={ServicesLayout}
                        />
                        <Route
                            exact
                            path={siteMapUrl()}
                            component={SiteMap}
                        />
                        <Route
                            exact
                            path={jobDescriptionUrl()}
                            component={jobDescription}
                        />
                        <Route
                          component={NotFound}
                        />
                    </Switch>
                    <Footer />
                </div>
            </div>
        );
    
}


// class PrimaryLayout extends PureComponent {
//     render() {
//         usePageViews();
//         return (
//             <div>
//                 <div>
//                     <Header />
//                     <Switch>
//                         <Route
//                             exact
//                             path={rootUrl()}
//                             component={HomeLayout}
//                         />
//                         <Route
//                             exact
//                             path={contactUrl()}
//                             component={ContactIndex}
//                         />
//                         <Route
//                             exact
//                             path={insightsUrl()}
//                             component={InsightsIndex}
//                         />
//                         <Route
//                             exact
//                             path={aboutUrl()}
//                             component={AboutIndex}
//                         />
//                         <Route
//                            // exact
//                             path={careersUrl()}
//                             component={CareersIndex}
//                         />
//                         <Route
//                             //exact
//                             path={servicesUrl()}
//                             component={ServicesLayout}
//                         />
//                         <Route
//                             exact
//                             path={siteMapUrl()}
//                             component={SiteMap}
//                         />
//                         <Route
//                             exact
//                             path={jobDescriptionUrl()}
//                             component={jobDescription}
//                         />
//                         <Route
//                           component={NotFound}
//                         />
//                     </Switch>
//                     <Footer />
//                 </div>
//             </div>
//         );
//     }
// }


export default PrimaryLayout;
