
import React, { PureComponent } from 'react';
import { Row, Col, Container, Card,CardTitle, CardBody, CardText, CardImg} from "reactstrap";
// import { CardIndex } from "../";
// import {PageTitle} from '../../../Base/components/'
import './HomeIndexChallenges.scss';


class HomeIndexChallenges extends PureComponent {


    render() {
        const {
            // groupLinkTo,
            challenges,
        } = this.props;

        return (
            <Container className="challenges-component">
                <Row>
                    <Col md="12">
                        <div align="left">
                            <h1 className="card-list-header font-weight-bold " >
                                Helping to Solve Your Toughest Challenges
                            </h1>
                            <p> We apply our expertise to help you overcome your toughest obstacles.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {/* TODO
                    check what happened to the on hover shadow
                    it was working yesterday and today its gone
                    */}
                    {
                        challenges.map(challenge => (
                            <Col md="6">
                                 <div className="mt-4">
                                    <div >
                                        <p className="challenge-card-topic">
                                            <a href={challenge.groupLinkTo} className=" challenge-card-topic-link">{challenge.group}</a>
                                        </p>
                                    </div>
                                    <a className="challenge-card-a" href={challenge.to}>
                                        <Card className="challenge-card" >
                                            <div>
                                                <CardImg top src={challenge.asset} alt="Card image cap" />
                                            </div>

                                            <div >
                                                <CardBody>
                                                    <CardTitle className="challenge-card-title">{challenge.header}</CardTitle>
                                                    <CardText className="challenge-card-description">{challenge.description}</CardText>
                                                    {/* <Button className="pull-right  float-right challenge-lean-more challenge-learn-btn" color="primary" href={challenge.to}>Learn More</Button> */}
                                                </CardBody>
                                            </div>

                                        </Card>
                                    </a>
                                </div>
                            </Col>
                        ))
                    }

                </Row>
            </Container>
        );
    }
}


export default HomeIndexChallenges;

// TODO
// pixel size is fixed
// enable mobile support for images
// the card is already mobile enables but the images have a fixed size 