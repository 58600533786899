import { arrayOf, string, shape, number } from 'prop-types';

export const cardType = shape({
    title: string.isRequired,
    titleSubtitle: string.isRequired,
    titleText: string.isRequired,
    asset: string.isRequired,
    id: string,
})

export const serviceItemType = shape({
    items: arrayOf({
        id: number.isRequired,
        title: string.isRequired,
        asset: string.isRequired,
        to: string.isRequired
    })
})

