import React, { PureComponent } from 'react';
import { Row, Col, Container, Jumbotron, Media} from "reactstrap";
import { ServiceItem } from '../../../../Base/components';

import './ServiceMultiple.scss';

class ServiceMultiple extends PureComponent {


    render() {
        const {
            serviceName,
            serviceDescription,
            to,
            serviceList
        } = this.props;


        return (
            <Jumbotron fluid className="service-jumbotron">
                <Container fluid>
                    <Row>
                        <Col md="6">
                            <ServiceItem
                                serviceName={serviceName}
                                serviceDescription={serviceDescription}
                                to={to}
                            />
                        </Col>
                        <Col md="6">

                            {
                                serviceList.map(service => {
                                    return (
                                        <Media>
                                            <Media left top href="#">
                                                <Media object src="service-double-bc1.jpg" alt="Generic placeholder image" className="service-item-multiple-image" />
                                            </Media>
                                            <Media body>
                                                <Media heading>
                                                    {service.title}
                                                  </Media>
                                                Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                                        </Media>
                                        </Media>
                                    )
                                })
                            }



                        </Col>
                    </Row>
                </Container>
            </Jumbotron>


        );
    }
}

export default ServiceMultiple;

// TODO
// DROPPING OFF THE SERVICE WITH MEDIA COMPONENT 
// USE THIS WITH BOX PATTERN LATER WHEN YOU HAVE CASE STUDIES


// <ServiceMultiple
// serviceName="Advisory"
// serviceDescription="Our Advisory Services help you identify threats, respond to attacks, protect data and privacy, and manage identities in the midst of large-scale digital change."
// to="#"
// serviceList={[
//     {
//         "title": "Cloud & Platforms",
//         "asset": "/single-service1.1.jpg",
//         "to": "#"
//     },
//     {
//         "title": "Blockchain",
//         "asset": "/single-service1.1.jpg",
//         "to": "#"
//     },
//     {
//         "title": "Advisory",
//         "asset": "/single-service1.1.jpg",
//         "to": "#"
//     },
// ]}
// />