import React, { useState, useEffect, useRef } from 'react'
// import fetch from 'unfetch'
import { useMiniSearch } from 'react-minisearch'
import './MiniSearch.scss'

const searchItems = [{"id":0,"rank":1,"title":"Cloud","link":"/services/cloud",}
,{"id":1,"rank":2,"title":"AWS","link":"/services/cloud/aws"}
,{"id":2,"rank":3,"title":"Azure","link":"/services/cloud/microsoft-azure"}
,{"id":3,"rank":4,"title":"you were on my mind","artist":"we five","year":"1965"}
,{"id":4,"rank":5,"title":"Simplify cloud ops","link":"/services/simplify-cloud-ops"}
,{"id":5,"rank":6,"title":"Kubernetes","link":"/services/simplify-cloud-ops/kubernetes"}
,{"id":6,"rank":7,"title":"delivery pipeline automation","link":"/services/delivery-pipeline-automation"}
,{"id":7,"rank":8,"title":"CICD","link":"/services/delivery-pipeline-automation/cicd"}
,{"id":8,"rank":9,"title":"Build Better Apps","link":"/services/enterprise-apps"}
,{"id":9,"rank":10,"title":"Build Apps","link":"/services/enterprise-apps/application-development"}
,{"id":10,"rank":11,"title":"Application Modernization","link":"/services/enterprise-apps/application-modernization"}
,{"id":11,"rank":12,"title":"Contact","link":"/contact"}
,{"id":12,"rank":13,"title":"About","link":"/about"}
,{"id":13,"rank":14,"title":"Careers","link":"/careers"}
,{"id":14,"rank":15,"title":"Backend Developer","link":"/careers"}
,{"id":15,"rank":16,"title":"SEO Specialist","link":"/careers"}
,{"id":16,"rank":17,"title":"DevOps Engineer ","link":"/careers"}
,{"id":17,"rank":18,"title":"React.js Developer","link":"/careers"}
,{"id":18,"rank":19,"title":"User Interface Designer","link":"/careers"}
,{"id":19,"rank":20,"title":"Offices","link":"/about"}
,{"id":20,"rank":21,"title":"Jobs","link":"/careers"}

]



const MiniSearch = ({ documents }) => {
  const {
    search,
    searchResults,
    clearSearch,
    // autoSuggest,
    suggestions,
    clearSuggestions,
    isIndexing
  } = useMiniSearch(documents, miniSearchOptions)

  const [query, setQuery] = useState('')
  const [selectedSuggestion, selectSuggestion] = useState(-1)
  const [searchOptions, setSearchOptions] = useState({
    fuzzy: 0.3,
    prefix: true,
    fields: ['title', 'link'],
    combineWith: 'OR',
    filter: null
  })

  // Perform search when the query changes
  useEffect(() => {
    if (query.length > 1) {
      search(query, searchOptions)
    } else {
      clearSearch()
    }
  }, [query, searchOptions])

  const searchInputRef = useRef(null)

  const deselectSuggestion = () => {
    selectSuggestion(-1)
  }

  const topSuggestions = suggestions ? suggestions.slice(0, 5) : []

  const handleChange = ({ target: { value } }) => {
    setQuery(value)
    if (value.length > 1) {
    } else {
      clearSuggestions()
    }
    deselectSuggestion()
    }

  const handleKeyDown = ({ which, key, keyCode }) => {
    if (key === 'ArrowDown') {
      selectSuggestion(Math.min(selectedSuggestion + 1, topSuggestions.length - 1))
    } else if (key === 'ArrowUp') {
      selectSuggestion(Math.max(-1, selectedSuggestion - 1))
    } else if (key === 'Enter' || key === 'Escape') {
      deselectSuggestion()
      clearSuggestions()
      searchInputRef.current.blur()
    }
  }

  const handleSearchClear = () => {
    setQuery('')
    deselectSuggestion()
    clearSuggestions()
  }

  const handleAppClick = () => {
    deselectSuggestion()
    clearSuggestions()
  }
  


  const setSearchOption = (option, valueOrFn) => {
    if (typeof valueOrFn === 'function') {
      setSearchOptions({ ...searchOptions, [option]: valueOrFn(searchOptions[option]) })
    } else {
      setSearchOptions({ ...searchOptions, [option]: valueOrFn })
    }
    search(query, searchOptions)
  }

  return (
    <div className='App' onClick={handleAppClick}>
      <article className='main' >
        {
          <Header
            onChange={handleChange} onKeyDown={handleKeyDown}
            onSearchClear={handleSearchClear} value={query} suggestions={topSuggestions} 
            searchInputRef={searchInputRef} searchOptions={searchOptions} setSearchOption={setSearchOption}
          />
        }
        {
          searchResults && searchResults.length > 0
            ? <SearchList results={searchResults} />
            : (isIndexing ? <Loader text='Indexing...' /> : <Explanation />)
        }
        {
          searchResults && searchResults.length === 0 &&
          <div className="notMatching">
            <h6>Your search did not match any results</h6>
            <h6>Try different keywords</h6>
            <div>
              <ul>
                <h6>Choose from popular searches</h6>
                <li><a href="/services/cloud">Cloud</a></li>
                <li><a href="/services/delivery-pipeline-automation">DevOps</a></li>
                <li><a href="/services/enterprise-apps/application-development">Build Apps</a></li>
              </ul>
            </div>
          </div>
        }
      </article>
    </div>
  )
}

const SearchList = ({ results }) => (
  <div className="sLinks">

  <ul className='SearchListt'>
    { results.map(({ id, ...props }) => 
       <SearchLinks className="SearchInternalItem" {...props} key={id} />
       ) }
  </ul>
  </div>
)

const SearchLinks = ({ title, link,rank,id }) => (
    <li className='searchLinks'>
    <a href={link} className={title}>{ title }</a>
   </li>

 
)


const Header = (props) => (
  <header className='Header'>
    <SearchBox {...props} />
  </header>
)

function SearchBox(props){
  const [show, setshow] = useState(false)
  let {onChange,
  onKeyDown,
  // onSearchClear,
  value,
  searchInputRef,
} = props
  return(
  <div className='SearchBox'>
    <div className='Search'>
      <input className="SearchInput" type='text' value={value} onChange={onChange} onKeyDown={onKeyDown} onFocus={()=>{
        setshow(true)
        }} ref={searchInputRef}
        autoComplete='none' autoCorrect='none' autoCapitalize='none' spellCheck='false' placeholder="Search" />
      {/* <button className='clear' onClick={onSearchClear}>&times;</button> */}
    </div>
    { 
      show&&value.length==0&&
      <div className="popularSearches">
         <h6>Choose from popular searches</h6>
         <p><a href="/services/cloud">Cloud</a></p>
         <p><a href="/services/delivery-pipeline-automation">DevOps</a></p>
         <p><a href="/services/enterprise-apps/application-development">Build App</a></p> 
    </div>
    }
  </div>
  )
}

const Explanation = () => (
  <p>
  </p>
)

const Loader = ({ text }) => (
  <div className='Loader'>{ text || 'loading...' }</div>
)

// const capitalize = (string) => string.replace(/(\b\w)/gi, (char) => char.toUpperCase())

const stopWords = new Set(['the', 'a', 'an', 'and','or'])

const years = []
for (let y = 1965; y <= 2015; y++) { years.push(y) }

const miniSearchOptions = {
  fields: ['link', 'title',],
  storeFields: ['year'],
  processTerm: (term, _fieldName) => (term.length <= 1 || stopWords.has(term)) ? null : term.toLowerCase()
}

// Fetch the JSON documents
const withDocuments = (Component) => {
  const WithDocuments = (props) => {
    const [documents, setDocuments] = useState(null)

    useEffect(() => {
      setDocuments(searchItems)
    }, [])

    if (documents == null) {
      return <Loader />
    } else {
      return <Component {...props} documents={documents} />
    }
  }

  return WithDocuments
}

export default withDocuments(MiniSearch)