import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { arrayOf, shape, string } from 'prop-types';
import { upperCase} from 'lodash';
import './BreadcrumbNavIndex.scss'
const BreadcrumbNavIndex = ({ breadcrumbs }) => {
    return (
            <Breadcrumb>
                {
                    breadcrumbs.map(b => {
                        if (b.href) {
                            return (
                                <BreadcrumbItem tag={Link} key={b.children} to={b.href} >
                                    {b.children}
                                </BreadcrumbItem>
                            );
                        }
                        return (
                            <BreadcrumbItem active key={b.children} tag="span">
                                {b.children}
                            </BreadcrumbItem>
                        );
                    })
                }
            </Breadcrumb>
    );
};


export default BreadcrumbNavIndex;

BreadcrumbNavIndex.propTypes = {
    breadcrumbs: arrayOf(
        shape({
            children: string.isRequired,
            href: string
        }),
    ).isRequired,
};