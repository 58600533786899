import React, { Component } from 'react'
import './ContactSales.scss'
import ContactSalesForm from './ContactSalesForm'
// import {PageTitle} from '../../../Base/components/'

 class ContactSales extends Component {
    render() {
        return (
            <div className="leftRightContainer">
                {/* <PageTitle text={"Contact Sales"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} /> */}
                <div className="left">
                    <div className="content">
                        <h1>Contact Sales</h1>
                        <p>Our sales team is here to help you find the right solutions — so you can grow your business, increase efficiency and deliver the future. Submit the form to get started.</p>
                    </div>
                </div>
                <div className="right"></div>
                <ContactSalesForm />
            </div>
        )
    }
}

export default ContactSales
