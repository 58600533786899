import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import './Footer.scss';
import { FooterLocations, FooterServices } from '../../Base/components';
// import {  siteMapUrl } from "../../Base/helpers/url";
import CookieConsent from "react-cookie-consent";
import CookieCon from '../../Base/components/CookieCon/CookieCon';
import Services from '../../Base/components/Services/Services';
// import HomePageHeader from '../../Base/components/HomePageHeader/HomePageHeader';
// import ServicesBanner from '../../Base/components/ServicesBanner/ServicesBanner';
// import { withTheme } from 'styled-components';
// import MiniSearch from '../../Base/components/MiniSearch/MiniSearch';
class Footer extends PureComponent {


    render() {

        const description =
            "By leveraging our full spectrum strategy, design, and technology capabilities," +
            " we deliver game-changing outcomes for our clients around the world.";

        return (
            <footer className="footer">
                {/* <Services /> */}
                {/* <Container> */}
                <Row>
                    <Col md={{ size: 3, offset: 1 }}>
                        <Row>
                            <Col className="footer-company-logo" md="12">
                                <h2 className="d-flex flex-row ">
                                    {/* XivTech */}
                                    <img className="img1" src="/logo2.svg" alt="" /><img src="/Text1.svg" alt="" />
                                </h2>
                            </Col>
                            <Col md="12" className="footer-company-description">
                                <p>
                                    {description}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="8">
                        <Row className="footer-list">

                            <Col md="2 m-3">
                                <FooterLocations
                                    locations={
                                        {
                                            header: "Locations",
                                            list: [
                                                "Toronto",
                                                "New Delhi"
                                            ]
                                        }
                                    }

                                />
                            </Col>
                            <Col md="3 m-3">

                                <FooterServices 
                                    services={
                                        {
                                            header: "Services",
                                            list: [
                                                {
                                                    serviceName: "Complete AI + RPA Automation",
                                                    to: "/services/AIandRPAautomation",
                                                    id: 1,
                                                },
                                                {
                                                    serviceName: "Cloud Services",
                                                    to: "/services/cloud",
                                                    id: 2,
                                                },
                                                {
                                                    serviceName: "Delivery Pipeline Automation",
                                                    to: "/services/delivery-pipeline-automation",
                                                    id: 3,
                                                },
                                                {
                                                    serviceName: "Simplify Cloud Ops",
                                                    to: "/services/simplify-cloud-ops",
                                                    id: 4,
                                                },
                                                {
                                                    serviceName: "Build Better Apps",
                                                    to: "/services/enterprise-apps",
                                                    id: 5,
                                                },
                                            ]
                                        }
                                    }
                                    />
                            </Col>
                            <Col md="2 m-3">
                                <h7 className="footer-company-header">
                                    XivTech
                                </h7>
                                <div className="footer-company-items mt-3">
                                    <p><a href="/about">About</a></p>
                                    <p><a href="/careers">Careers</a></p>
                                    <p><a href="/contact">Contact</a></p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="site_map"> 
                    &copy; 2021 XivTech, Inc. All Rights Reserved 
                    {/* <a className="ml-3 text-white" href={siteMapUrl()}>sitemap</a> */}
                </div>
              
                {/* </Container> */}
                <CookieConsent
                 buttonText="I accept"
                 expires={30}
                //  debug={true}
                 disableButtonStyles={true}
                 buttonStyle={{background: "#ffffff",color: "#2272FB",fontWeight:"bold",marginTop:"0px",border:"0px",margin:"0px"}}
                 contentClasses="text-left m-0"
                 containerClasses="col-lg-5 "
                 contentStyle={{width:"300px",fontSize:"14px"}}
                 style={{background:"white", color:"black",boxShadow:"-.1px -.1px 3px #afa9a9, .1px .1px 3px #afa9a9",borderRadius:"2rem",paddingBottom:"7px",paddingTop:"12px",paddingRight:"1.9%",margin:"1% 1%",width:"98%"}}
                 >
                 This website uses <CookieCon /> to ensure you get the best experience on our website
                </CookieConsent>
            </footer>
        );
    }
    
}

export default Footer;


// TODO

// add a bottom line above the all rights reserved