import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle} from '../../../Base/components/'
import { rootUrl } from '../../../Base/helpers/url'

import './ContactIndex.scss';
import ContactSales from '../../../Base/components/ContactSales/ContactSales';


class ContactIndex extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            { children: 'Contact' }
        ]

        return (
            <div>
                <PageTitle text={"Contact Sales"} description={"XivTech’s sales team is here to help you find the right solutions — so you can grow your business, increase efficiency and deliver the future. Submit the form to get started."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                {/* <Container>
                    <Row>
                        <Col>
                            <TempPara
                                paragraph={[
                                    {
                                        header: "Contact Us",
                                        subheader: "https://www.cognizant.com/about-cognizant/",
                                        description: "Contact Us button will be persistent on the top right corner. Dropdown fields - Sales, Request a Demo, Free Cloud Assessment, Let's Partner, Everything Else",
                                        // items: [
                                        //     "para1 hello1",
                                        //     "para1 hello2"
                                        // ],
                                    },
                                    {
                                        header: "About 222us header",
                                        subheader: "Hello this is the subheader",
                                        description: "this is he description para1. blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah  blah blah ",
                                        items: [
                                            "para1 hello1",
                                            "para1 hello2"
                                        ],
                                    },
                                    {
                                        header: "",
                                        subheader: "",
                                        description: " ",
                                        items: [
                                            "",
                                            ""
                                        ],
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                </Container> */}
                        <ContactSales />

            </div>
        );
    }
}


export default ContactIndex;
