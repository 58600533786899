import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HomeIndex} from '../'
import { NotFound } from '../../Base/components'
import { rootUrl} from "../../Base/helpers/url";


class HomeLayout extends PureComponent {

    render() {
        return (

            <div>
                <div>
                    <Switch>
                        <Route
                            exact
                            path={rootUrl()}
                            component={HomeIndex}
                        />
                        <Route
                            exact
                            component={NotFound}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}


export default HomeLayout;
