import React, { Component } from 'react'
import './HomePageHeader.scss'

class HomePageHeader extends Component {
    render() {
        return (
            <div className="headerParentDiv">
                <div className="spanContainer">
                    <span>
                    Bot, Process, Efficiency
                    </span>
                </div>
                <div className="h1Container">
                    <h1>
                    Robotic Process Automation that unifies people, process and technology
                    </h1>
                </div>
                <div className="pContainer">
                    <p>
                    We help business leaders embrace AI-Powered Automation as they endeavor to visualize the future.</p>
                </div>
            </div>
        )
    }
}

export default HomePageHeader;