import React, { PureComponent } from 'react';
import { Row, Col,Button } from "reactstrap";

import './ServiceItem.scss';

class ServiceItem extends PureComponent {


    render() {
        const {
            serviceName,
            serviceDescription,
            to
        } = this.props;


        return (

            <Row fluid>
                <Col className=" service-item">
                    <h1 className="service-item-header">{serviceName}</h1>
                    <h5 className="service-item-description">{serviceDescription}</h5>
                    <Button href={to} className="view-service-tile-btn service-item-btn" >Explore This Service</Button>
                </Col>
            </Row>


        );
    }
}

export default ServiceItem;
