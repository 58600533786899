import React, { Component } from 'react'
import "./ServiceSearchItem.scss"
import { Row, Col, Collapse, Button, } from 'reactstrap';

class ServiceSearchItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        return (
            <div className="container serviceSerachItem">
                <a href={this.props.link} >
                    {this.props.title}
                    <img className="arrowLink" src="/Arrow-black.svg" alt="arrow-black" />
                </a>
                <span color="primary" onClick={this.toggle} style={{ marginLeft: '3rem' }}>+</span>
                <Collapse isOpen={this.state.isOpen}>
                    {this.props.text}
                </Collapse>
            </div>
        )
    }
}


export default ServiceSearchItem;