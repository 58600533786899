import React, { PureComponent } from 'react';
import {Container, Jumbotron } from "reactstrap";

import './ServicesHero.scss';

class ServicesHero extends PureComponent {


    render() {
        const {
            asset,
            header,
            description
        } = this.props;

        const divStyle = {
            backgroundImage: `linear-gradient(to right,rgba(15, 15, 15, 0.4),rgba(15, 15, 15, 0.4)),url('${asset}')`,
        };

        return (

            <div>
                <Jumbotron fluid style={divStyle} className="services-hero" >
                    <Container fluid className="overlay text-color-light">
                        <h1 className="display-3">{header}</h1>
                        <p className="lead">{description}</p>
                    </Container>
                </Jumbotron>
            </div>


        )
    }
}

export default ServicesHero;
