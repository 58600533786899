import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,  CardListIndex,ServicesHero, ServicesAction } from '../../../../Base/components'
import {BuildDeployScaleBotsUrl} from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';
import Stats from '../../../../Base/components/Stats/Stats';


class AIandRPAautomation extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: "Build, Deploy and Scale bots" },
        ]

        const serviceActionDescription = `Our team of professionals help you deliver measurable business outcomes by strategizing, designing and executing results driven AI + RPA automation.

        With Ekisu - our flagship AI  automation product, we help you with end-to end automation for your workflows. Each automation helps you save time, reduce cost of human capital, and improve efficiency! Now that’s successful Digital Transformation!`


        return (
            <div>
                <PageTitle text={"Drive Efficiency, Automate and Monetize"} description={"XivTech’s simplify cloud ops service provides cloud management services for your business. Find out about our services; Kubernetes and much more. Let's talk."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    // asset={"/rp3.jpg"}
                    asset={"/download.png"}
                    header="AI + RPA = Enabling End-to-End Automation"
                    description="Drive Efficiency, Automate and Monetize"
                    
                />


                <ServicesAction
                    title1 = "Process"
                    title2 = "Automation"
                    title3 = "Monetization"
                    description={serviceActionDescription}
                    classForGT="buildBots"
                />

                <Container>

                    <Row>
                        <Col>

                            <CardListIndex
                                headerName={"Capabilities"}
                                cards={[
                                    {
                                        title: "Bot Automation Simplified",
                                        titleSubtitle: "Build, Deploy and Scale bots",
                                        // titleText: "Automation, Optimization, Orchestration and Scale",
                                        asset: "/rp4.jpg",
                                        id: "1",
                                        to: BuildDeployScaleBotsUrl()
                                    },

                                ]}
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}


export default AIandRPAautomation;
