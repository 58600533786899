import React, { PureComponent } from 'react';
import {string } from 'prop-types';

import { Row, Col, Container } from "reactstrap";
import { ServiceItem, ServiceItemImage } from '../../../../Base/components';
import { serviceItemType } from '../../../../Base/types';

import './ServiceTriple.scss';

class ServiceTriple extends PureComponent {


    render() {

        const {
            serviceName,
            serviceDescription,
            to,
            items
        } = this.props;

        return (
            <Container fluid>
                <Row>
                    <Col md="6">
                        <ServiceItem
                            serviceName={serviceName}
                            serviceDescription={serviceDescription}
                            to={to}
                        />
                    </Col>
                    <Col md="6">
                        {
                            (items.length > 0) &&
                            <Row>
                                <Col md="12">
                                    <ServiceItemImage
                                        serviceItemTitle={items[0].title}
                                        asset={items[0].asset}
                                        to={items[0].to}
                                    />
                                </Col>
                                <Col md="12">
                                    <Row>
                                        <Col md="6">
                                            <ServiceItemImage
                                                serviceItemTitle={items[1].title}
                                                asset={items[1].asset}
                                                to={items[1].to}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <ServiceItemImage
                                                serviceItemTitle={items[2].title}
                                                asset={items[2].asset}
                                                to={items[2].to}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>

        );
    }
}

ServiceTriple.defaultProps = {
    serviceName: undefined,
    serviceDescription: undefined,
    to: undefined,
    items: []
};


ServiceTriple.propTypes = {
    serviceName: string.isRequired,
    serviceDescription: string.isRequired,
    to: string.isRequired,
    items: serviceItemType.isRequired
};





export default ServiceTriple;
