import React, { PureComponent } from 'react';
import { Row, Col, CardDeck } from 'reactstrap';
import { CardIndex } from '../';
import { cardType } from '../../types'
import { string, arrayOf } from 'prop-types';

import './CardListIndex.scss';

class CardListIndex extends PureComponent {

    render() {

        const {
            cards,
            headerName,
        } = this.props;

        // TODO
        // change the align from the html to the css
        return (
            <div>
                <Row>
                    <Col md="12">
                        <h1 className="card-list-header" align="left">
                            {headerName}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    {
                        cards.map(card => (
                            <Col xs="12" md="4" key={card.id}>
                                <CardDeck>
                                    <CardIndex
                                        title={card.title}
                                        titleSubtitle={card.titleSubtitle}
                                        titleText={card.titleText}
                                        asset={card.asset}
                                        to={card.to}
                                    />
                                </CardDeck>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        );
    }
}

export default CardListIndex;

CardListIndex.propTypes = {
    cards: arrayOf(cardType).isRequired,
    headerName: string.isRequired,
};

