import React, { PureComponent } from 'react';
import { Row, Col, Container} from "reactstrap";
import { ServiceItem, ServiceItemImage } from '../../../../Base/components';
import './ServiceDouble.scss';

class ServiceDouble extends PureComponent {


    render() {
        const {
            serviceName,
            serviceDescription,
            to
        } = this.props;

        return (
            <Container className="main-service " >
                <Row className="main-row">
                    <Col md="6">
                        <ServiceItem
                            serviceName={serviceName}
                            serviceDescription={serviceDescription}
                            to={to}
                        />
                    </Col>
                    <Col md="6" >

                        <Row >
                            <Col md="12" >
                                <ServiceItemImage
                                    className="service-main-image"
                                    // className="service-double-item-top"
                                    serviceItemTitle="Cloud & Platforms 1"
                                    asset="/single-service1.1.jpg"
                                    to="#"
                                />
                            </Col>
                            <Col md="12" className="service-double-item-bottom">
                                <ServiceItemImage
                                    className="service-double-item-bottom"
                                    serviceItemTitle="Cloud & Platforms 2"
                                    asset="/single-service1.1.jpg"
                                    to="#"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default ServiceDouble;
