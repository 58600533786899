import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import './WorkWithUs.scss'
import { NavLink } from 'react-router-dom';
// import { jobDescriptionUrl } from "../../../Base/helpers/url";
// import { Helmet } from "react-helmet";

export default class WorkWithUs extends Component {
    constructor(props) {
        super(props)
        this.selectLocation = React.createRef()
        this.state = {
            showModal: false,
            all: true,
            business: false,
            design: false,
            engineering: false,
            marketing: false,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.allJobs = this.allJobs.bind(this);
        this.businessJobs = this.businessJobs.bind(this);
        this.designJobs = this.designJobs.bind(this);
        this.engineeringJobs = this.engineeringJobs.bind(this);
        this.marketingJobs = this.marketingJobs.bind(this);
        this.allFalse = this.allFalse.bind(this);
        this.focusTextInput = this.focusTextInput.bind(this);

    }
    focusTextInput() {
        return this.selectLocation.current.value;
    }
    allFalse() {
        this.setState(prevState => ({
            all: false,
            business: false,
            design: false,
            engineering: false,
            marketing: false
        }));
    }
    show = () => {
        this.setState(prevState => ({
            showModal: true,

        }));
    }
    hide = () => {
        this.setState(prevState => ({
            showModal: false,

        }));
    }


    allJobs = () => {
        this.allFalse();
        this.setState(prevState => ({
            all: true,

        }));
    }

    businessJobs = () => {
        this.allFalse();
        this.setState(prevState => ({
            business: true
        }));
    }

    designJobs = () => {
        this.allFalse();
        this.setState(prevState => ({
            design: true
        }));
    }
    engineeringJobs = () => {
        this.allFalse();
        this.setState(prevState => ({
            engineering: true
        }));
    }

    marketingJobs = () => {
        this.allFalse();
        this.setState(prevState => ({
            marketing: true
        }));
    }

    render() {
        const jobDescription = [
            {
                "id": "1",
                "title": "React.js Developer",
                "location": "NewDelhi/Remote",
                "description": {
                    "jobSummary": "We are looking for a great JavaScript developer who is proficient with React.js. Your primary focus will be on developing user interface components and implementing them following well-known React.js workflows (such as Flux or Redux). In order for you to succeed in this role, you will need to be proficient in JavaScript, React, HTML, CSS, and have solid knowledge and experience in programming applications.",
                    "responsibilities": [
                        <ul>
                            <li>In-depth knowledge of JavaScript, HTML5, CSS3, SASS, Bootstrap and front-end languages</li>
                            <li>Knowledge of REACT tools including React.js, Redux and Enzyme</li>
                            <li>Knowledge of performance testing frameworks including Mocha and Jest.</li>
                            <li>Experience with browser-based debugging and performance testing software</li>
                            <li>Immediate Joiners are preferred with Relevant Experience: 2 -3 Years Min</li>
                            <li>Education: B.Tech/B.E. in Any Specialization or BCA/MCA. Other specializations with relevant training/experience</li>
                            <li>Freshers with relevant trainings and certifications will also be considered for entry level positions</li>
                        </ul>
                    ],
                    // "basicQualifications": [
                    //     "5 years of experience or more as an engineer or systems administrator, with a high focus on DevOps.",
                    //     "Ability to communicate effectively and help foster a positive working environment.",
                    //     "Excellent Knowledge of Linux (Redhat based distro preferred, Ubuntu), with well established experience with Linux Systems Administration.",
                    //     "Experience, deploying, and managing highly scalable, fault-tolerant service deployments.",
                    //     "Experience working with some type of VM virtualization framework.",
                    //     "Experience working to some capacity with Kubernetes or container based clustering technologies.",
                    //     "Strong scripting skills (BASH/SH, or code bases such as perl/python/etc.)",
                    //     "Working knowledge of Layer 2/3 switching, VLAN, VXLAN, inter-VLAN routing, LACP, Etherchannel, subnetting, NAT, PNATs"
                    // ],
                    // "requiredEducation": [
                    //     "BS/BE/BA in Computer Science, Computer Engineering, or related discipline"
                    // ]
                },
                "category": {
                    "id": "1",
                    "description": "Engineering & Emerging Technologies"
                }
            },
            {
                "id": "2",
                "title": "Backend Developer",
                "location": "NewDelhi/Remote",
                "description": {
                    "jobSummary": "We are looking for a Backend developer (Java/Node.js) responsible for building microservice applications. Your primary responsibility will be design and development of all server-side logic, integration with databases and to coordinate with the rest of the team working on different layers of the infrastructure. You will also be responsible for integrating the front-end elements built by your co-workers into the application.",
                    "responsibilities": [
                          <ul>
                              <li>Proficient in Java and Node.js </li>
                              <li>Proficient in My SQL, Postgres, Redis and MongoDB</li>
                              <li>Familiarity with concepts of MVC, JDBC, Microservices and RESTful</li>
                              <li>Experience with popular web application frameworks, such as Spring Boot and Express</li>
                              <li>Implementing automated testing platforms and unit tests</li>
                              <li>Proficient understanding of code versioning tools, such as Git and familiarity with continuous integration</li>
                              <li>Familiarity with build tools such as Maven</li>
                              <li>Immediate Joiners are preferred with Relevant Experience: 2 -3 Years Min</li>
                              <li>Education: B.Tech/B.E. in Any Specialization or BCA/MCA. Other specializations with relevant training/experience</li>
                              <li>Freshers with relevant trainings and certifications will also be considered for entry level positions</li>
                          </ul>
                    ],
                    // "basicQualifications": [
                    //     "5 years of experience or more as an engineer or systems administrator, with a high focus on DevOps.",
                    //     "Ability to communicate effectively and help foster a positive working environment.",
                    //     "Excellent Knowledge of Linux (Redhat based distro preferred, Ubuntu), with well established experience with Linux Systems Administration.",
                    //     "Experience, deploying, and managing highly scalable, fault-tolerant service deployments.",
                    //     "Experience working with some type of VM virtualization framework.",
                    //     "Experience working to some capacity with Kubernetes or container based clustering technologies.",
                    //     "Strong scripting skills (BASH/SH, or code bases such as perl/python/etc.)",
                    //     "Working knowledge of Layer 2/3 switching, VLAN, VXLAN, inter-VLAN routing, LACP, Etherchannel, subnetting, NAT, PNATs"
                    // ],
                    // "requiredEducation": [
                    //     "BS/BE/BA in Computer Science, Computer Engineering, or related discipline"
                    // ]
                },
                "category": {
                    "id": "1",
                    "description": "Engineering & Emerging Technologies"
                }
            },
            {
                "id": "3",
                "title": "DevOps Engineer",
                "location": "NewDelhi/Remote",
                "description": {
                    "jobSummary": "We are looking for a DevOps engineer who is an expert in Docker and containerization technologies. They must have knowledge about Terraform and container orchestration tools like Kubernetes and logging/monitoring tools for these orchestration tools. They will also be responsible for building and improving our application CI/CD pipelines.",
                    "responsibilities": [
                        <ul>
                            <li>Experience withDocker, Kubernetes, Jenkins and Terraform</li>
                            <li>Experience implementing Continuous Integration and Continuous Delivery pipelines (DevOps / SecOps).</li>
                            <li>Experience with monitoring tools like Prometheus, Grafana, Datadog, etc</li>
                            <li>Managing production infrastructure with Terraform</li>
                            <li>Strong Linuxsystem administration experience</li>
                            <li>Familiar with AWS and Azure</li>
                            <li>Immediate Joiners are preferred with Relevant Experience: 2 -3 Years Min</li>
                            <li>Education: B.Tech/B.E. in Any Specialization or BCA/MCA. Other specializations with relevant training/experience</li>
                            <li>Freshers with relevant trainings and certifications will also be considered for entry level positions</li>
                        </ul>
                    ],
                    // "basicQualifications": [
                    //     "5 years of experience or more as an engineer or systems administrator, with a high focus on DevOps.",
                    //     "Ability to communicate effectively and help foster a positive working environment.",
                    //     "Excellent Knowledge of Linux (Redhat based distro preferred, Ubuntu), with well established experience with Linux Systems Administration.",
                    //     "Experience, deploying, and managing highly scalable, fault-tolerant service deployments.",
                    //     "Experience working with some type of VM virtualization framework.",
                    //     "Experience working to some capacity with Kubernetes or container based clustering technologies.",
                    //     "Strong scripting skills (BASH/SH, or code bases such as perl/python/etc.)",
                    //     "Working knowledge of Layer 2/3 switching, VLAN, VXLAN, inter-VLAN routing, LACP, Etherchannel, subnetting, NAT, PNATs"
                    // ],
                    // "requiredEducation": [
                    //     "BS/BE/BA in Computer Science, Computer Engineering, or related discipline"
                    // ]
                },
                "category": {
                    "id": "1",
                    "description": "Engineering & Emerging Technologies"
                }
            },
            {
                "id": "4",
                "title": "SEO Specialist",
                "location": "NewDelhi/Remote",
                "description": {
                    "jobSummary": "We are looking for an SEO/SEM expert to manage all search engine optimization and marketing activities. You will be responsible for managing all SEO activities and will be working on projects to improve search engine crawlability and indexation.",
                    "responsibilities": [
                        <ul>
                            <li>Knowledge of organic search and how SEO activities influence search engine ranking algorithms</li>
                            <li>Experience with any SEO tools, including Stat, Majestic, BrightEdge, Deep Crawl, Screaming Frog, Majestic, SEM Rush, & Ahrefs</li>
                            <li>Familiar with Google platforms, including Google Search Console, Google Analytics, Google Data Studio, and Google Keyword Planner</li>
                            <li>Immediate Joiners are preferred with 2 years of SEO experience and online marketing</li>
                            <li>Education:  Any specializations with relevant training/experience</li>
                            <li>Freshers with relevant trainings and certifications will also be considered for entry level positions</li>
                        </ul>
                    ],
                    // "basicQualifications": [
                    //     "5 years of experience or more as an engineer or systems administrator, with a high focus on DevOps.",
                    //     "Ability to communicate effectively and help foster a positive working environment.",
                    //     "Excellent Knowledge of Linux (Redhat based distro preferred, Ubuntu), with well established experience with Linux Systems Administration.",
                    //     "Experience, deploying, and managing highly scalable, fault-tolerant service deployments.",
                    //     "Experience working with some type of VM virtualization framework.",
                    //     "Experience working to some capacity with Kubernetes or container based clustering technologies.",
                    //     "Strong scripting skills (BASH/SH, or code bases such as perl/python/etc.)",
                    //     "Working knowledge of Layer 2/3 switching, VLAN, VXLAN, inter-VLAN routing, LACP, Etherchannel, subnetting, NAT, PNATs"
                    // ],
                    // "requiredEducation": [
                    //     "BS/BE/BA in Computer Science, Computer Engineering, or related discipline"
                    // ]
                },
                "category": {
                    "id": "2",
                    "description": "Digital Marketing"
                }
            },
            {
                "id": "4",
                "title": "User Interface Designer",
                "location": "NewDelhi/Remote",
                "description": {
                    "jobSummary": "We are looking for an experienced and talented UI designer to design and shape unique, user-centric products and experiences. The ideal candidate will have experience working in agile teams, with developers, UX designers and copywriters. You will be able to make deliberate design decisions and to translate any given user-experience journey into sitemaps, user flows, customer journey maps, wireframes, mockups and prototypes.",
                    "responsibilities": [
                       <ul>
                           <li>Experience with at least one design tool such as Figma, Sketch, InVision, Adobe XD or equivalent</li>
                           <li>Create and develop wireframes, mockups, and interactive prototypes as needed</li>
                           <li>Communicate scenarios, end-to-end experiences, models, and designs to all relevant stakeholders</li>
                           <li>A solid grasp of user-centered design (UCD), planning and conducting user research, user testing, rapid prototyping, heuristic evaluation, usability and accessibility concerns</li>
                           <li>Understanding of basic front-end languages: HTML5, CSS3 and Javascript</li>
                           <li>Immediate Joiners are preferred with Relevant Experience: 2 -3 Years Min</li>
                           <li>Education: Bachelor’s/Master’s degree or related certificate/experience in Graphic Design, Digital Arts, Fine Arts, Human Computer Interaction or related field.</li>
                           <li>Freshers with relevant trainings and certifications will also be considered for entry level positions</li>
                       </ul>
                    ],
                    // "basicQualifications": [
                    //     "5 years of experience or more as an engineer or systems administrator, with a high focus on DevOps.",
                    //     "Ability to communicate effectively and help foster a positive working environment.",
                    //     "Excellent Knowledge of Linux (Redhat based distro preferred, Ubuntu), with well established experience with Linux Systems Administration.",
                    //     "Experience, deploying, and managing highly scalable, fault-tolerant service deployments.",
                    //     "Experience working with some type of VM virtualization framework.",
                    //     "Experience working to some capacity with Kubernetes or container based clustering technologies.",
                    //     "Strong scripting skills (BASH/SH, or code bases such as perl/python/etc.)",
                    //     "Working knowledge of Layer 2/3 switching, VLAN, VXLAN, inter-VLAN routing, LACP, Etherchannel, subnetting, NAT, PNATs"
                    // ],
                    // "requiredEducation": [
                    //     "BS/BE/BA in Computer Science, Computer Engineering, or related discipline"
                    // ]
                },
                "category": {
                    "id": "2",
                    "description": "Design"
                }
            }
        ]
        return (
            <div className="workWithUs">
                <div className="container pt-5">
                    <h2 className="text-left">Work with us</h2>
                    <div className="h2underline"></div>
                    <br />
                    <Row>

                        <Col md="8">
                            <p className="text-left">We are growing and always looking for passionate people! There are many interesting positions open across our offices. Take a look at some of our current openings and drop us a line.</p>
                        </Col>
                    </Row>
                    <br />
                    <br /><br />
                    <div className="departments">
                        <p onClick={this.allJobs}>All</p>
                        <p onClick={this.engineeringJobs}>Engineering & Emerging Technologies</p>
                        <p onClick={this.marketingJobs}>Digital Marketing</p>
                        <p  onClick={this.designJobs}>Design</p>
                        
                    </div>
                    <div className="horizontalline"></div>

                    <br /><br /><br />

                    {
                        this.state.all &&
                        jobDescription.map((job) => {

                            return <NavLink
                                to={{
                                    pathname: "/jobdescription",
                                    id: job.id,
                                    title: job.title,
                                    location: job.location,
                                    jobSummary: job.description.jobSummary,
                                    responsibilities: job.description.responsibilities,
                                    basicQualifications: job.description.basicQualifications,
                                    requiredEducation: job.description.requiredEducation,
                                    category: job.category.description
                                }}
                            >
                                <div className="card jobsDiv">
                                    <div className="card-body">
                                        <h5 className="card-title">{job.title}</h5>
                                        <h6 className="card-subtitle mb-2 mt-2 text-muted">{job.location}</h6>
                                    </div>
                                </div>
                            </NavLink>

                        })
                    }

                    {
                        this.state.engineering &&
                        jobDescription.map((job) => {
                            if (
                                job.title !== "SEO Specialist" && job.title !== "User Interface Designer"
                            )
                                return <NavLink
                                    to={{
                                        pathname: "/jobdescription",
                                        id: job.id,
                                        title: job.title,
                                        location: job.location,
                                        jobSummary: job.description.jobSummary,
                                        responsibilities: job.description.responsibilities,
                                        basicQualifications: job.description.basicQualifications,
                                        requiredEducation: job.description.requiredEducation,
                                        category: job.category.description
                                    
                                    }}
                                >

                                    <div className="card jobsDiv">
                                        <div className="card-body">
                                            <h5 className="card-title">{job.title}</h5>
                                            <h6 className="card-subtitle mb-2 mt-2 text-muted">{job.location}</h6>
                                        </div>
                                    </div>
                                </NavLink>
                        })
                    }


                    {
                        this.state.marketing &&
                        jobDescription.map((job) => {
                            if (
                                job.title == "SEO Specialist"
                            )
                                return <NavLink
                                    to={{
                                        pathname: "/jobdescription",
                                        id: job.id,
                                        title: job.title,
                                        location: job.location,
                                        jobSummary: job.description.jobSummary,
                                        responsibilities: job.description.responsibilities,
                                        basicQualifications: job.description.basicQualifications,
                                        requiredEducation: job.description.requiredEducation,
                                        category: job.category.description
                                        
                                    }}
                                >
                                    <div className="card jobsDiv">
                                        <div className="card-body">
                                            <h5 className="card-title">{job.title}</h5>
                                            <h6 className="card-subtitle mb-2 mt-2 text-muted">{job.location}</h6>
                                        </div>
                                    </div>
                                </NavLink>
                        })
                    }
                    {
                        this.state.design &&
                        jobDescription.map((job) => {
                            if (
                                job.title == "User Interface Designer"
                            )
                                return <NavLink
                                    to={{
                                        pathname: "/jobdescription",
                                        id: job.id,
                                        title: job.title,
                                        location: job.location,
                                        jobSummary: job.description.jobSummary,
                                        responsibilities: job.description.responsibilities,
                                        basicQualifications: job.description.basicQualifications,
                                        requiredEducation: job.description.requiredEducation,
                                        category: job.category.description
                                        
                                    }}
                                >
                                    <div className="card jobsDiv">
                                        <div className="card-body">
                                            <h5 className="card-title">{job.title}</h5>
                                            <h6 className="card-subtitle mb-2 mt-2 text-muted">{job.location}</h6>
                                        </div>
                                    </div>
                                </NavLink>
                        })
                    }

                </div>

            </div>
        )
    }
}
