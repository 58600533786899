import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import './TempPara.scss';

class TempPara extends PureComponent {

    render() {

        const {
            paragraph
        } = this.props;

        return (
            <div>
                {paragraph &&
                    <div>
                        {
                            paragraph.map(para => (
                                <div>
                                    <h1 className="mb-4 mt-0">{para.header}</h1>
                                    <h3>{para.subheader}</h3>
                                    <p>{para.description}</p>
                                    {
                                        para.items &&
                                        <ul>
                                            {
                                                para.items.map(item => (
                                                    < li >
                                                        {item}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        );
    }
}

export default TempPara;

TempPara.propTypes = {
    indexName: string.isRequired,
    indexDescription: string.isRequired,
    to: string.isRequired,
};


// this component can be exported out as without css and css can be imported int he index file 
// whiich would then allow this component to be used as with css and without css
