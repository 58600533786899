import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList,  ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl, enterpriseAppsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './BuildNewApps.scss';


class BuildNewApps extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'EnterpriseApps', href: enterpriseAppsUrl() },
            { children: 'BuildNewApps Services' }
        ]

        //TODO
        // CHECK IF THIS COMMENT CAN BE USED ANYWHERE 
        // "Get to market more quickly by integrating DevOps, Continuous Delivery, Microservices and Containers in application development.",

        const serviceActionDescription = `In today's high-tech world, digitalizing business has become crucial. The increasing investment by businesses in digital initiatives are driven by the customers who expect services and products to be delivered fast and efficient. 

        We offer robust platforms to streamline your business with a mobile-first approach that fits into your budget. We can craft, custom-design, add rich functionalities and enhance productivity which will help you reach out to the target market. We adapt to your business needs by working on business to business (B2B) or business to consumer (B2C) structure. `;
            // "With Cloud-Native, we help enable Rapid Responsiveness, Innovative Features, and Zero Downtime. " +
            // "We help you accelerate business velocity and growth by embracing rapid change, large scale and resilence. " +
            // "Our expertise and focus on the Twelve-Factor App and the API First principles allow us to build true cloud-native apps.";
            
        return (
            <div>
                <PageTitle text={"Deliver New Apps Users Love"} description={"XivTech develops new apps with future proof technology. We offer robust platforms to streamline your business with a mobile-first approach that fits into your budget."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/m1.webp"}
                    header="Deliver New Apps Users Love"
                    description="We Develop New Applications with Future-Proof Technology."
                />

                <ServicesAction
                    title1=  "Develop"
                    title2 = "Deliver"
                    title3 = "Expand"
                    description={serviceActionDescription}
                    classForGT="buildNewApps"
                />

                {/* <Container> */}


                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits Cloud-Native Apps."}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Mobile Strategies",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "We adopt a mobile-first approach, allowing us to develop both for the web and mobile platforms. Services include application development on the Web, Android, and iOS. ",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "App Development",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Our specialists will analyze your business’s goals and needs with respect to the current market. We will pick the best option that fits and prioritize usability, learnability and data security when building an application for you.",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "UI/ UX Design",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "We design your application according to the market trend that fits best with your brand. We offer detailed assessment, prototyping, user experience design and user interface to uncover and choose from endless choices.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },

                                    {
                                        id: "4",
                                        title: "Cloud Native ",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "Our Cloud-first mindset, enables us to provide future-ready solutions and services. We enable business transformation with optimal cloud adoption strategies.",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },
                                    {
                                        id: "5",
                                        title: "Quality Control",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-1/summarytile_copy_cop/parsys.coreimg.png/1579165126972.png",
                                        // subTitle: "",
                                        description: "Our specialists will implement the best practices for functional, usability, performance and integration testing. We ensure to establish outstanding application performance and customer experience.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },

                                    {
                                        id: "6",
                                        title: "Maintenance and Support",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-2/summarytile/parsys.coreimg.png/1579165114555.png",
                                        // subTitle: "",
                                        description: "We will work with you to maintain and enhance your application as per industry demands. Our maintenance service will provide your business with maximum efficiency at a reasonable cost.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "7",
                                    //     title: "Cloud Native Applications",
                                    //     // subTitle: "",
                                    //     description: "Our Cloud expertise allows us to co-create and deploy cloud native applications with modern microservice and dev-ops architecture. We help transfer skiils to your team to dramatically scale innovation and expertise across your organization.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "8",
                                    //     title: "CI/CD",
                                    //     // subTitle: "",
                                    //     description: "Our expertise with continuous integration, and continuous delivery, or CI/CD, helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and relaibility. We help train your team, empowering you to stay ahead of the competition.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "9",
                                    //     title: "HashiCorp",
                                    //     // subTitle: "",
                                    //     description: "Leverage native Azure services to deliver powerful applications that are economical to run",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // }, {
                                    //     id: "10",
                                    //     title: "Data Migration",
                                    //     // subTitle: "",
                                    //     description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "11",
                                    //     title: "AI Services",
                                    //     // subTitle: "",
                                    //     description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "12",
                                    //     title: "Blockchain Services",
                                    //     // subTitle: "",
                                    //     description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                    //     list: [
                                    //         // "Service Item 1",
                                    //         // "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                {/* </Container> */}

            </div>
        );
    }
}


export default BuildNewApps;
