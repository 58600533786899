import React, { Component } from 'react'
import { Row,Col } from 'reactstrap'
import "./Stats.scss"


class Stats extends Component {
    render() {
        return (
            <div className="mainStatsDiv">
                <Row>
                    <Col md={4}>
                     <div className="experienceDiv">
                       <p>
                       Bitnami <span>Experience </span>In Your Hands
                       </p>
                       <div className="bottomLine"></div>
                     </div>
                    </Col>

                    <Col md={4}>
                       <div>
                           <div>
                               <h3>1M+</h3>
                               <p>Deployments Per Month</p>
                           </div>

                           <div>
                               <h3>130+</h3>
                               <p>Open Source Applications</p>
                           </div>
                       </div>
                    </Col>

                    <Col md={4}>
                        <div>
                           <div>
                               <h3>Every</h3>
                               <p>Major Cloud</p>
                           </div>

                           <div>
                               <h3>Any</h3>
                               <p>Deployment Format</p>
                           </div>
                       </div>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default Stats;