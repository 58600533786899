import React, { PureComponent } from 'react';
import { Nav, NavItem, NavLink, DropdownToggle, Media, Dropdown, Row, Col } from 'reactstrap';
import { RequestDemoButton, Logo, SearchBar } from '../';
import ReactDOM from 'react-dom';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
    Accordion,
    Card,
} from 'react-bootstrap';
import {
    contactUrl, aboutUrl, careersUrl,
    simplifyCloudOpsUrl, cicdServiceUrl, kubernetesServiceUrl,
    cloudServiceHomeUrl, azureServiceUrl, appDevelopmentServiceUrl,
    awsServiceUrl, enterpriseAppsUrl, appModernizationServiceUrl,AIandRPAautomationUrl,rpa1Url,BuildDeployScaleBotsUrl
} from '../../../Base/helpers';
import {
    deliveryPipelineAutomationUrl, servicesUrl
} from '../../helpers/url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { PageTitle } from '../../../Base/components/'

import './CommonNavBar.scss';
// import AppSec from './NavbarSecondary';
import { Link } from 'react-router-dom';





class CommonNavBar extends PureComponent {

    constructor(props) {
        super(props)
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateShadow = this.updateShadow.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            dropdownOpen: false,
            collapsed: true,
            setCollapsed: true,
            showlinks: false,
            showDots: false,
            fontweight: true,
            shadow: false
        }
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.clickbars = this.clickbars.bind(this);
        this.clickDots = this.clickDots.bind(this);
        this.fontweightChange = this.fontweightChange.bind(this);

    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.updateShadow);
        document.addEventListener('click', this.handleClickOutside, true);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.updateShadow);
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    updateShadow() {
        this.setState({ shadow: true })
    }


    handleClickOutside(event) {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                showlinks: false,
                fontweight: true,
            });
        }

    }

    toggleNavbar = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }
    fontweightChange = () => {
        this.setState(prevState => ({
            fontweight: !prevState.fontweight,
        }));
    }


    onMouseEnter() {
        this.setState({ dropdownOpen: true });
    }

    onMouseLeave() {
        this.setState({ class: false });
    }
    clickbars = () => {
        this.setState(prevState => ({
            showlinks: !prevState.showlinks,
        }));

    }
    clickDots = () => {
        this.setState(prevState => ({
            showDots: !prevState.showDots,
        }));

    }


    render() {


        const services = [
            {
                id: 1,
                imgAsset: "/rp1.jpg",
                serviceHomeName: "Complete AI + RPA Automation",
                serviceHomeUrl: AIandRPAautomationUrl(),
                list: [
                    {
                        serviceName: "Build, Deploy, and Scale Bots",
                        serviceUrl: BuildDeployScaleBotsUrl(),
                    },

                ]
            },
            {
                id: 2,
                imgAsset: "/a2.jpg",
                serviceHomeName: "Build Better Apps - The Cloud Native Way",
                serviceHomeUrl: enterpriseAppsUrl(),
                list: [
                    {
                        serviceName: "Build Apps - Deliver new apps users love",
                        serviceUrl: appDevelopmentServiceUrl(),
                    },
                    {
                        serviceName: "Modernize Apps - Rearchitect valuable legacy apps",
                        serviceUrl: appModernizationServiceUrl(),
                    }
                ]
            },

            {
                id: 3,
                imgAsset: "/cl2.jpg",
                serviceHomeName: "Simplify Cloud Ops - Apply The New Rules Of Ops",
                serviceHomeUrl: simplifyCloudOpsUrl(),
                list: [
                    {
                        serviceName: "Kubernetes - Enterprise-ready runtime",
                        serviceUrl: kubernetesServiceUrl(),
                    },
                    {
                        serviceName: "CICD",
                        serviceUrl: cicdServiceUrl(),
                    },
                ]
            },
            // {
            //     id: 3,
            //     imgAsset: "/a1.jpg",
            //     serviceHomeName: "Delivery Pipeline Automation - Build Better Deploy Faster",
            //     serviceHomeUrl: deliveryPipelineAutomationUrl(),
            //     list: [
            //         // {
            //         //     serviceName: "CICD",
            //         //     serviceUrl: cicdServiceUrl(),
            //         // },

            //     ]
            // },


            {
                id: 4,
                imgAsset: "/a1.jpg",
                serviceHomeName: "Cloud Services",
                serviceHomeUrl: cloudServiceHomeUrl(),
                list: [
                    {
                        serviceName: "Azure",
                        serviceUrl: azureServiceUrl(),
                    },
                    {
                        serviceName: "AWS",
                        serviceUrl: awsServiceUrl(),
                    }
                ]
            },
        ]
        return (
            <div className={this.state.showlinks ? "navShadow2" : "navShadow"}>
                <Row className={this.state.dropdownOpen ? "nav-item2 nav2" : "nav"}>
                    <Col md="12">
                        <Row>
                            <Col className="nav-logo d-flex justify-content-center" md="3" >
                                <Logo class={this.state.dropdownOpen ? "logo2" : "logo"} imgLink={this.state.dropdownOpen ? "Text1.svg":"Text.svg"}/>
                            </Col>

                            <Col className={this.state.showlinks && this.state.width + 'px' <= '956px' ? "mainItemsMobileView" : "col-sm-7 mainitems"}>
                                <Nav className={this.state.dropdownOpen ? "nav2 text-white" : "nav-items "}>
                                    <NavItem className="servicesitem">
                                        <Dropdown
                                            isOpen={this.state.dropdownOpen}
                                            toggle={this.toggle}
                                            onMouseOver={this.onMouseEnter}
                                        // onMouseLeave={this.onMouseLeave}
                                        >
                                            <DropdownToggle //caret
                                                tag="span"
                                                data-toggle="dropdown"
                                                aria-expanded={this.state.dropdownOpen}
                                            >
                                                <NavLink className={this.state.dropdownOpen ? "text-white" : "nav-item nav-item-dropdown"}>
                                                    Services
                                                <i className="nav-item-dropdown-caret"> <FontAwesomeIcon icon={faAngleDown} /> </i>
                                                </NavLink>
                                            </DropdownToggle>
                                        </Dropdown>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href={careersUrl()} className={this.state.dropdownOpen ? "text-white" : "nav-item"}>Careers</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href={aboutUrl()} className={this.state.dropdownOpen ? "text-white" : "nav-item"}>About</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href={contactUrl()} className={this.state.dropdownOpen ? "text-white" : "nav-item"}>Contact</NavLink>
                                    </NavItem>
                                </Nav>
                                <Nav className={this.state.dropdownOpen ? "nav2" : ""}>
                                    <NavItem className={this.state.dropdownOpen ? "nav2 text-white" : "common-navbar-searchbar"}>
                                        <SearchBar class={this.state.dropdownOpen ? "searchbar-icon2" : "searchbar-icon"} />
                                    </NavItem>
                                </Nav>

                            </Col>

                            <FontAwesomeIcon className="faicon fa-2x m-2" style={this.state.showlinks ? { color: 'black' } : { color: 'black' }} onClick={this.clickbars} icon={this.state.showlinks ? faTimes : faBars} />

                            {/* <Col md="2 searchdemo">
                                <Nav>
                                <NavItem className="common-navbar-searchbar">
                                <SearchBar />
                                </NavItem>
                                </Nav>
                            </Col> */}

                            <div className="rdemo">
                                <RequestDemoButton />
                            </div>
                        </Row>
                    </Col>
                </Row>
                {/* <div className={this.state.shadow ? "planeSpace":""}></div> */}

                {
                    (this.state.showlinks && this.state.width + 'px' <= '956px') &&
                    <div className="mobileViewItems">
                        <div className="logotext">
                            <a href={'/'} style={{ color: 'black' }} >
                                <h3>XivTech</h3>
                            </a>
                        </div>
                        <Accordion defaultActiveKey="0" className="accordion" >
                            <Card className="accordCard" >
                                <Card.Header className="cardHeader">
                                    <Accordion.Toggle as={Link} variant="" eventKey="0" className="accordiontoggle" onClick={this.fontweightChange}>
                                        <div className="toggle-link">
                                            <h4 style={this.state.fontweight ? { fontWeight: 'bold' } : { fontweight: 'lighter', marginLeft: "-1px" }} >Services  </h4> <FontAwesomeIcon className="icon" icon={this.state.fontweight ? faAngleUp : faAngleDown} />
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="ml-3">
                                        <div className="underlineh2"></div>

                                        <div className="tech-services">
                                            <div>
                                                <h3><a href="/services/AIandRPAautomation">Complete AI + RPA Automation</a></h3>
                                                <a href={BuildDeployScaleBotsUrl()}>Build, Deploy and Scale Bots</a>
                                            </div>
                                            <div>
                                                <h3><a href="/services/enterprise-apps"> Build Better Apps - The Cloud Native Way</a></h3>
                                                <a href={appDevelopmentServiceUrl()}>Build Apps - Deliver New Apps Users Love</a>
                                                <a href={appModernizationServiceUrl()}>Modernize Apps - Rearchitect Valuable Legacy Apps</a>
                                            </div>

                                            <div>
                                                <h3><a href="/services/simplify-cloud-ops">Simplify Cloud Ops - Apply The New Rules Of Ops</a> </h3>
                                                <a href={kubernetesServiceUrl()}>Kubernetes - Enterprise-Ready Runtime</a>
                                            </div>

                                            <div>
                                                <h3><a href="/services/cloud"> Cloud Services</a></h3>
                                                <a href={azureServiceUrl()}>Azure</a>
                                                <a href={awsServiceUrl()}>Aws</a>
                                            </div>

                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <div className="links">
                            <a href={careersUrl()}>Careers</a>
                            <a href={contactUrl()}>Contact</a>
                            <a href={aboutUrl()}>About</a>
                        </div>

                    </div>
                }


                <Row>
                    <Col>
                        {
                            this.state.dropdownOpen &&

                            <div className="nav-sub-panel">

                                <Row>
                                    {services.map(service => {
                                        return (
                                            <Col md={{ size: 6 }}>
                                                <div>
                                                    <Row>
                                                        <Col md="12">
                                                            <div>
                                                                <div className="mt-4">
                                                                    <div >
                                                                        <p className="challenge-card-topic mb-3">
                                                                            <a href={service.serviceHomeUrl} className=" challenge-card-topic-link" >{service.serviceHomeName}</a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <Media >
                                                                    <Media   >
                                                                        <Media object src={service.imgAsset} className="nav-sub-panel-item-image" />
                                                                    </Media>
                                                                    <Media body>

                                                                        {
                                                                            service.list.map(serviceDetail => {
                                                                                return (
                                                                                    <div>
                                                                                        <Media className="nav-sub-panel-item-media-list" >
                                                                                            <a href={serviceDetail.serviceUrl}>{serviceDetail.serviceName}</a>
                                                                                        </Media>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Media>
                                                                </Media>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Col>

                                        )
                                    })}
                                </Row>

                            </div>

                        }
                    </Col>
                </Row>
            </div >

        );
    }

}

export default CommonNavBar;
