import React, { PureComponent } from 'react';
import {  Row, Col, Card, CardBody, CardTitle} from 'reactstrap';
import './HomeTeaserTile.scss';

class HomeTeaserTile extends PureComponent {

    render() {

        const {
            title,
            // description,
            asset
        } = this.props;

        return (
            <div className="home-teaserr">
                <Row>
                    {/* <Col xs={{ size: 5 }} className="column">
                        <Card body className="home-teaser-con">
                            <CardText className="home-teaser-con-title">{title}</CardText>
                        </Card>
                    </Col> */}
                    <Col xs={5}>
                        <Card className="leftcard">
                            <CardBody>
                                <CardTitle><p>{title}</p></CardTitle>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={7}>
                        <img className="rightimg" width="100%" height="100%" src={asset} alt="noimage" />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HomeTeaserTile;