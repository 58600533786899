import React, { PureComponent } from 'react';
import { Row, Col, Container } from "reactstrap";
import { ServiceItem, ServiceItemImage } from '../../../../Base/components';
// import { azureServiceUrl } from '../../../../Base/helpers/url';

import './ServiceSingle.scss';

class ServiceSingle extends PureComponent {


    render() {
        const {
            serviceName,
            serviceDescription,
            to,
            item
        } = this.props;

        return (
            <Container fluid>
                <Row>
                    <Col md="6">
                        <ServiceItem
                            serviceName={serviceName}
                            serviceDescription={serviceDescription}
                            to={to}
                        />
                    </Col>
                    <Col md="6">
                        <ServiceItemImage
                            serviceItemTitle={item.title}
                            asset={item.asset}
                            to={item.to}
                        />
                    </Col>
                </Row>
            </Container>


        );
    }
}

export default ServiceSingle;
