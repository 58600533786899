import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrimaryLayout } from './Primary/layouts';
import { NotFound } from './Base/components';
import './App.scss';
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-1PRJ9FL4NL');

function App(){
    // useEffect(() => {
    //   ReactGA.pageview(window.location.pathname + window.location.search);
    // })
    return (
      <div className="App">
        <BrowserRouter >
          <Switch>
            <Route
              path="/"
              component={PrimaryLayout}
            />
            <Route
              component={NotFound}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );

}

// class App extends Component {

//   render() {
//     return (
//       <div className="App">
//         <BrowserRouter >
//           <Switch>
//             <Route
//               path="/"
//               component={PrimaryLayout}
//             />
//             <Route
//               component={NotFound}
//             />
//           </Switch>
//         </BrowserRouter>
//       </div>
//     );
//   }
// }


export default App;
