import React, { PureComponent } from 'react';
import { CommonNavBar } from '../../Base/components'
// import { CommonNavBar2 } from '../../Base/components'
import './Header.scss';

class Header extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
            planeDiv : false
        }
        this.toggle = this.toggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.updatePlaneDiv = this.updatePlaneDiv.bind(this);

    }
    
    componentDidMount() {
        console.log(`********`);
        console.log(process.env.REACT_APP_POST_CONTACT_FORMS);
        window.addEventListener('scroll', this.updatePlaneDiv);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updatePlaneDiv);
    }
    
    updatePlaneDiv(){
        this.setState({planeDiv: true})
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    onMouseEnter() {
        this.setState({ dropdownOpen: true });
    }

    onMouseLeave() {
        this.setState({ dropdownOpen: false });
    }

    render() {

        return (
            <div>
                <div className={this.state.planeDiv ? "planeSpace":"planeSpace"}></div>
                <CommonNavBar />
            </div>
            
        );
    }

}

export default Header;
