import React, { PureComponent } from 'react';
import { HowWeWork, ViewServicesTile, ReachoutTile, ContinueConversationTile } from '../../../Base/components'
import { Row, Col } from "reactstrap";
import { Suspense } from 'react';
import './HomeIndex.scss';

const HomeBanner = React.lazy(() => {return import("../../../Base/components/HomeBanner/HomeBanner")});
const HomeTeaserTile = React.lazy(() => {return import("../../../Base/components/HomeTeaserTile/HomeTeaserTile")});
const HomeIndexChallenges = React.lazy(() => {return import("../../../Base/components/HomeIndexChallenges/HomeIndexChallenges")});

class HomeIndex extends PureComponent {
    
    render() {
        const serviceDescription =
            "At XivTech, we help growing companies reach their potential by enabling business transformation through the alignment" +
            "of people, process and technology. Our objective as a Trusted Advisor is to improve your operational efficiency and increase " +
            " corporate effectiveness leading you to greater profitability.";
        return (
            <div>
                <Row>
                    <Col>
                    <Suspense fallback={<h1>Loading…</h1>}>
                        <HomeBanner
                            indexName="Customer-Focussed Digital Transformation"
                            asset="/v5"
                        />
                    </Suspense>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <ReachoutTile /> */}
                    </Col>
                </Row>
                <Row className="home-teaser-tile">
                    <Col >
                    <Suspense fallback={<h1>Loading…</h1>}>
                        {/* <HomeTeaserTile
                            // serviceHeader={"What Are You trying To Solve? "}
                            title={"We help our clients focus on what they do best"}
                            description={
                                [
                                    serviceDescription
                                ]
                            }
                            asset={"/main1.webp"}
                            /> */}
                            </Suspense>
                    </Col>
                </Row>

                <Row>
                    <Col className="home-bg-color-gray home-section-margin">
                    <Suspense fallback={<h1>Loading…</h1>}>
                        {/* <HomeIndexChallenges
                            challenges={[
                                {

                                    id: "1",
                                    header: "Determine the Right Cloud Strategy",
                                    description: "Our cloud advisory specialists will help you devise the best Cloud-First Digital Strategy. We will securely manage your multi cloud environment so you can focus on accelerating innovation and optimizing performance.",
                                    asset: "a1.webp",
                                    to: "/services/cloud/microsoft-azure",
                                    group: "Cloud",
                                    groupLinkTo: "/services/cloud"
                                },
                                {
                                    id: "2",
                                    header: "Get Faster Without Breaking Things",
                                    description: "Add to the bottom-line by automating the software delivery process to derive value for both the IT as well as the business teams. Create higher quality software, deliver on customer expectations and business goals.",
                                    asset: "a2.webp",
                                    to: "/services/delivery-pipeline-automation/cicd",
                                    group: "DevOps",
                                    groupLinkTo: "/services/delivery-pipeline-automation"
                                },
                                {
                                    id: "3",
                                    header: "Modernize and Rationalize your Applications",
                                    description: "Accelerate digital innovation with app modernization. Modern apps, help you gain greater efficiency, agility and scalability. Valuable insights from your data can help you to improve your overall customer experience.",
                                    asset: "a3.webp",
                                    to: "/services/enterprise-apps/application-modernization",
                                    group: "Build Apps",
                                    groupLinkTo: "/services/enterprise-apps"
                                },
                                {
                                    id: "4",
                                    header: "Seamless Infrastructure Management",
                                    description: "Maximize the value of your cloud investment and drive innovation. Optimize IT services within the cloud environment to gain more speed, scalability, productivity and cost savings.",
                                    asset: "a4.webp",
                                    to: "/services/simplify-cloud-ops/kubernetes",
                                    group: "Cloud Operations ",
                                    groupLinkTo: "/services/simplify-cloud-ops"
                                },
                            ]}
                        /> */}
                        </Suspense>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <ViewServicesTile /> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <HowWeWork
                            qualities={[
                                {
                                    // "id": "1",
                                    // "header": "An unmatched ecosystem of partners",
                                    // "description": "We are the leading partner for AWS, Azure and Google and the #1 co-innovator with SAP and Oracle. Our relationships can help you field any challeng"
                                    "id": "1",
                                    "header": "Business Excellence",
                                    "description": "At XivTech, we enable the Digital Transformation journey for your business. Our experts will evaluate your specific business needs and goals, provide expert and custom solutions."
                                },
                                {
                                    // "id": "2",
                                    // "header": "Proven, industry-tailored solutions",
                                    // "description": "We work with you, drawing on the experience of 30,000+ cloud projects in nearly every industry, to build predictable, fast and secure cloud solutions."
                                    "id": "2",
                                    "header": "Cloud-first",
                                    "description": "Our Cloud-first mindset, enables us to provide future-ready solutions and services. We enable business transformation with optimal cloud adoption strategies."
                                },
                                {
                                    // "id": "3",
                                    // "header": "Unparalleled talent and experience",
                                    // "description": "Our 90,000+ cloud professionals wield more than 49,000+ certifications, combining vast industry experience with specialized, state-of-the art skillsets."
                                    "id": "3",
                                    "header": "Commitment",
                                    "description": "Our experienced specialists are committed to providing your business with exceptional value and customer experience. We build, modernize and streamline business processes and applications."
                                },
                            ]}
                        /> */}
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <PartnersTile
                            images={
                                [
                                    "kubernetes.png", "partner5.svg", "partner5.svg", "partner5.svg", 
                                ]
                            }
                        />
                    </Col>
                </Row> */}

                <Row>
                    <Col>
                        {/* <ContinueConversationTile /> */}
                    </Col>
                </Row>
            </div>
        );
    }
}


export default HomeIndex;

// TODO
// How we work spacing