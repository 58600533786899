import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { string } from 'prop-types';
import environment from '../../../environment';

class PageTitle extends PureComponent {

    render() {
        return (

            <Helmet>
                <html lang="en" />
                <title>
                     {this.props.text} | {environment.siteName}
                </title>
                <meta name="description" content= {this.props.descriptionn} />
                <meta name="theme-color" content="#E6E6FA" />
            </Helmet>
        );
    }
}


export default PageTitle;


PageTitle.propTypes = {
    text: string.isRequired,
    // description : string.isRequired
};