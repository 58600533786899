export const rootUrl = () => '/';

// HOME
export const aboutUrl = () => '/about';
export const careersUrl = () => '/careers';
export const contactUrl = () => '/contact';
export const insightsUrl = () => '/insights';
export const servicesUrl = () => '/services';

// ENTERPRISE APPS
export const enterpriseAppsUrl = () => '/services/enterprise-apps'; //new
export const appDevelopmentServiceUrl = () => '/services/enterprise-apps/application-development'; // use Cloud Native Component
export const appModernizationServiceUrl = () => '/services/enterprise-apps/application-modernization'; // new

// SIMPLIFY CLOUD OPS
export const simplifyCloudOpsUrl = () => '/services/simplify-cloud-ops'; //new
export const kubernetesServiceUrl = () => '/services/simplify-cloud-ops/kubernetes'; // use Kubernetes component
export const serviceMeshServiceUrl = () => '/services/simplify-cloud-ops/service-mesh'; // new
export const cicdServiceUrl = () => '/services/simplify-cloud-ops/cicd'; // use cicd component


// DELIVERY PIPELINE AUTOMATION
export const deliveryPipelineAutomationUrl = () => '/services/delivery-pipeline-automation'; // use Dev Ops component
// export const cicdServiceUrl = () => '/services/delivery-pipeline-automation/cicd'; // use cicd component
export const terraformServiceUrl = () => '/services/delivery-pipeline-automation/terraform'; // new
export const containerServiceUrl = () => '/services/delivery-pipeline-automation/containers'; // use containers component
 
// CLOUD SERVICES
export const cloudServiceHomeUrl = () => '/services/cloud';
export const azureServiceUrl = () => '/services/cloud/microsoft-azure';
export const awsServiceUrl = () => '/services/cloud/aws';

//RPA
export const AIandRPAautomationUrl = () => '/services/AIandRPAautomation';
export const rpa1Url = () => '/services/AIandRPAautomation/rpa1';
export const BuildDeployScaleBotsUrl = () => '/services/AIandRPAautomation/BuildDeployScaleBots';


//SiteMap
export const siteMapUrl = () => '/sitemap';

//jobDescription
export const jobDescriptionUrl = () => '/jobdescription';
