import React, { PureComponent } from 'react';
import { Row, Col, Container, Card, CardTitle, CardBody, CardText } from "reactstrap";

import {PageTitle} from '../../../Base/components/'
import './HowWeWork.scss';

class HowWeWork extends PureComponent {


    render() {

        const {
            qualities,
        } = this.props;



        return (
            <Container className="challenges-component mt-0">
                 <PageTitle text={"We strive for"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} />
                <Row>
                    <Col md="12">
                        <div align="left">
                            <h1 className="font-weight-bold mb-3 " >
                              We Strive for:
                             </h1>
                            <p className="mb-4">
                                Every project is different. After assessing your goals, we’ll work with you to define
                                the right solution for your business needs with a holistic approach that addresses
                                people and processes as well as technology.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        qualities.map(quality => (
                            <Col md="4">
                                <Card className="how-we-work-card" >
                                    <div className="how-we-work-item">
                                        <CardBody>
                                            <CardTitle className="how-we-work-item-header h5">{quality.header}</CardTitle>
                                            <CardText className="how-we-work-item-description">{quality.description}</CardText>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        );
    }
}

export default HowWeWork;
