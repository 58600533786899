import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ServicesHome } from '../'
import { CloudServicesHome, AzureServices, AWSServices } from '../../cloud-services'
import { DeliveryPipelineAutomationHome, CICDServices, ContainerServices, TerraformServices } from '../../delivery-pipeline-automation'
import { SimplifyCloudOps, KubernetesServices, ServiceMeshServices } from '../../simplify-cloud-ops';
import { EnterpriseApps, BuildNewApps,ModernizeApps} from '../../enterprise-apps';
import { AIandRPAautomation,rpa1,BuildDeployScaleBots} from '../../RPA';
import { NotFound } from '../../../Base/components';
import {
    servicesUrl, cloudServiceHomeUrl, azureServiceUrl, awsServiceUrl, simplifyCloudOpsUrl, containerServiceUrl, kubernetesServiceUrl,
    cicdServiceUrl, enterpriseAppsUrl, deliveryPipelineAutomationUrl, terraformServiceUrl, serviceMeshServiceUrl,appDevelopmentServiceUrl, appModernizationServiceUrl,AIandRPAautomationUrl,BuildDeployScaleBotsUrl,rpa1Url
} from '../../../Base/helpers/url';



class ServicesLayout extends PureComponent {

    render() {
        return (
            <div>
                <div>
                    <Switch>
                        <Route
                            exact
                            path={servicesUrl()}
                            component={ServicesHome}
                        />
                        <Route
                            exact
                            path={cloudServiceHomeUrl()}
                            component={CloudServicesHome}
                        />
                        <Route
                            exact
                            path={azureServiceUrl()}
                            component={AzureServices}
                        />
                        <Route
                            exact
                            path={awsServiceUrl()}
                            component={AWSServices}
                        />
                        <Route
                            exact
                            path={deliveryPipelineAutomationUrl()}
                            component={DeliveryPipelineAutomationHome}
                        />
                        <Route
                            exact
                            path={cicdServiceUrl()}
                            component={CICDServices}
                        />
                        <Route
                            exact
                            path={terraformServiceUrl()}
                            component={TerraformServices}
                        />
                        <Route
                            exact
                            path={containerServiceUrl()}
                            component={ContainerServices}
                        />

                        {/*  */}
                        <Route
                            exact
                            path={simplifyCloudOpsUrl()}
                            component={SimplifyCloudOps}
                        />
                        <Route
                            exact
                            path={kubernetesServiceUrl()}
                            component={KubernetesServices}
                        />
                        <Route
                            exact
                            path={serviceMeshServiceUrl()}
                            component={ServiceMeshServices}
                        />
                        <Route
                            exact
                            path={enterpriseAppsUrl()}
                            component={EnterpriseApps}
                        />
                         <Route
                            exact
                            path={appDevelopmentServiceUrl()}
                            component={BuildNewApps}
                        />
                        <Route
                            exact
                            path={appModernizationServiceUrl()}
                            component={ModernizeApps}
                        />
                        <Route
                            exact
                            path={AIandRPAautomationUrl()}
                            component={AIandRPAautomation}
                        />
                        <Route
                            exact
                            path={rpa1Url()}
                            component={rpa1}
                        />
                        <Route
                            exact
                            path={BuildDeployScaleBotsUrl()}
                            component={BuildDeployScaleBots}
                        />
                        
                        
                        <Route
                            component={NotFound}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}


export default ServicesLayout;
