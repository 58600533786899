import React, { PureComponent } from 'react';
import { Jumbotron } from 'reactstrap';
import { BreadcrumbNavIndex, PageTitle} from '../../../../Base/components'
import { ServiceSingle, ServiceDouble, ServiceTriple} from '../'

import './ServicesHome.scss';
import Services from '../../../../Base/components/Services/Services';
// import './dx.scss';


class ServicesIndex extends PureComponent {



    render() {
        const breadcrumbs = [
            { children: 'Cloud', href: "#adnan" },
            { children: 'DevOps', href: "#" },
            { children: 'Enterprise and Cloud Apps', href: "#" },
            { children: 'Blockchain', href: "#" },
            { children: 'Infrastructure as Code', href: "#" },
            { children: 'Service Mesh', href: "#" },
        ]

        const cloudServiceItem = {
            title: "Azure",
            asset: "/single-service1.1.jpg",
            // to: azureServiceUrl()
        }
        const enterpriseServiceItem = {
            title: "Cloud Native Applications",
            asset: "/single-service1.1.jpg",
            // to: appDevelopmentServiceUrl()
        }
        const infrastructureServiceItem = {
            title: "Terraform",
            asset: "/single-service1.1.jpg",
            // to: infrastructureAsCodeTerraformUrl()
        }
        const devOpsServiceItems =
            [
                {
                    id: 1,
                    title: "CI/CD",
                    asset: "/single-service1.1.jpg",
                    // to: cicdServiceUrl()
                },
                {
                    id: 2,
                    title: "Container Services",
                    asset: "/single-service1.1.jpg",
                    // to: containerServiceUrl()
                },
                {
                    id: 3,
                    title: "Kubernetes services",
                    asset: "/single-service1.1.jpg",
                    // to: kubernetesServiceUrl()
                }
            ]
        const serviceMeshItems =
            [
                {
                    id: 1,
                    title: "Consul",
                    asset: "/single-service1.1.jpg",
                    // to: consulServiceMeshUrl()
                },
                {
                    id: 2,
                    title: "Istio",
                    asset: "/single-service1.1.jpg",
                    // to: istioServiceMeshUrl()
                },
                {
                    id: 3,
                    title: "Spire",
                    asset: "/single-service1.1.jpg",
                    // to: spireServiceMeshUrl()
                }
            ]

        const blockchainItems =
            [
                {
                    id: 1,
                    title: "Hyperledger",
                    asset: "/single-service1.1.jpg",
                    // to: hyperledgerBlockchainServicesUrl()
                },
                {
                    id: 2,
                    title: "Azure Blockchain Service",
                    asset: "/single-service1.1.jpg",
                    // to: azureBlockchainServicesUrl()
                }
            ]


            // const divStyle1 = {
            //     backgroundImage:'url(./02-01_AppsMod_clr.png)',
            //   };


            // const divStyle2 = {
            //     backgroundImage:'url(./02-01_AppsMod_bw.png)',
            //   };

            //   const divStyle3 = {
            //     backgroundImage:'url(./02-02_AppDevServ_bw.png)',
            //   };


            // const divStyle4 = {
            //     backgroundImage:'url(./02-02_AppDevServ_clr.png)',
            //   };
         
            //   const divStyle5 = {
            //     backgroundImage:'url(./04-05_668x376_bw.jpg)',
            //   };


            // const divStyle6 = {
            //     backgroundImage:'url(./04-05_668x376_clr.jpg)',
            //   };
         


        return (
            <div>
                <PageTitle text={"Services"} />
                {/* <BreadcrumbNavIndex breadcrumbs={breadcrumbs} /> */}
                <Services />

{/* 
                <div className="grid grid-service mb-4 grid-article-2-alt">

                    <div className="grid-item grid-item-feature">
                        <div className="service-item service-item-feature service-item-theme-gray">
                            <div className="service-item-content">
                                <h2 className="service-item-title">
                                    Applications
                                </h2> 
                                <p className="service-item-subtitle">
                                    Our Applications Services help you develop, manage and modernize apps to deliver digital services and boost business results.
                                </p>
                                <a className="service-item-link btn btn-primary" href="/application_services">
                                    Explore this Service
                                </a>
                            </div>
                        </div>
                    </div>

                    <a href="/application_services/offerings/139843/148397-application_modernization" className="grid-item grid-item-full">
                        <div className="service-item service-item-link service-item-bg-image" style={divStyle1}>
                            <div className="service-item-bg-static" style={divStyle2}></div>
                            <div className="service-item-content">
                                <p className="service-item-title">
                                    <span>Application Modernization </span>
                                </p>
                            </div>
                        </div>
                    </a>


                    <a href="/application_services/offerings/139843/148387-dxc_applications_management_services" className="grid-item grid-item-half grid-item-bottom article-link">
                        <div className="service-item service-item-link service-item-bg-image" style={divStyle3}>
                            <div className="service-item-bg-static" style={divStyle4}></div>
                            <div className="service-item-content">
                                <p className="service-item-title"><span>Application Management Services </span></p>
                            </div>
                        </div>
                    </a>


                    <a href="/application_services/offerings/139843/148341-dxc_modern_application_development" classNameName="grid-item grid-item-half grid-item-right grid-item-bottom article-link">
                        <div className="service-item service-item-link service-item-bg-image" style={divStyle5}>
                            <div className="service-item-bg-static" style={divStyle6}></div>
                            <div className="service-item-content">
                                <p className="service-item-title">
                                    <span>Modern Application Development</span>
                                </p>
                            </div>
                        </div>
                    </a>
                </div> */}




                 {/* <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-light" >
                    <ServiceSingle
                        serviceName="Cloud"
                        serviceDescription="Our Cloud and Platform Services help you adopt and scale new cloud solutions globally while integrating with your traditional IT infrastructure."
                        // to={cloudServiceHomeUrl()}
                        item={cloudServiceItem}
                    />
                </Jumbotron>

                <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-dark">
                    <ServiceTriple
                        serviceName="Devops"
                        serviceDescription="Our Blockchain Services help you identify threats, respond to attacks, protect data and privacy, and manage identities in the midst of large-scale digital change."
                        // to={simplifyCloudOpsUrl()}
                        items={devOpsServiceItems}
                    />
                </Jumbotron>

                <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-light">
                    <ServiceSingle
                        serviceName="Enterprise And Cloud Apps "
                        serviceDescription="Our Cloud and Platform Services help you adopt and scale new cloud solutions globally while integrating with your traditional IT infrastructure."
                        // to={enterpriseAndCloudAppsHomeUrl()}
                        item={enterpriseServiceItem}
                    />
                </Jumbotron>

                <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-dark" >
                    <ServiceDouble
                        name="adnan"
                        serviceName="Blockchain"
                        serviceDescription="Our Blockchain Services help you identify threats, respond to attacks, protect data and privacy, and manage identities in the midst of large-scale digital change."
                        // to={blockchainServicesHomeUrl()}
                        items={blockchainItems}
                    />
                </Jumbotron>

                <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-light">
                    <ServiceSingle
                        serviceName="Infrastructure As Code"
                        serviceDescription="Our Cloud and Platform Services help you adopt and scale new cloud solutions globally while integrating with your traditional IT infrastructure."
                        // to={infrastructureAsCodeHomeUrl()}
                        item={infrastructureServiceItem}
                    />
                </Jumbotron>

                <Jumbotron fluid classNameName="service-jumbotron service-jumbotron-dark">
                    <ServiceTriple
                        serviceName="ServiceMesh"
                        serviceDescription="Our ServiceMesh Services help you identify threats, respond to attacks, protect data and privacy, and manage identities in the midst of large-scale digital change."
                        // to={serviceMeshHomeUrl()}
                        items={serviceMeshItems}
                    />
                </Jumbotron>  */}
            </div>
        );
    }
}


export default ServicesIndex;
