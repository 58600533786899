import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import LetUsTalk from '../LetUsTalk/LetUsTalk'
import './ReadyToStart.scss'

 class ReadyToStart extends Component {
    render() {
        return (
            <div className="readyToStart">
                <Row>
                    <Col md={6}>
                     <h1>Ready to start the conversation?</h1>
                     <p>We’re experts on your side, doing what it takes to get the job done right — from the first consultation to your daily operations.</p>
                    </Col>
                    <Col md={6} className="talkForm">
                       <LetUsTalk />
                    </Col>
                </Row>
            </div>
        )
    }
};

export default ReadyToStart
