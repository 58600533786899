import React, { PureComponent } from 'react';
import { Jumbotron } from "reactstrap";
import ContactForm from '../ContactForm/ContactForm';

import './ServicesAction.scss';

class ServicesAction extends PureComponent {


    render() {
        const {
            title1,
            title2,
            title3,
            description,
            classForGT
        } = this.props;

        // const divStyle = {
        //     backgroundImage: `linear-gradient(to right,rgba(15, 15, 15, 0.4),rgba(15, 15, 15, 0.4)),url('${asset}')`,
        // };

        return (

            <div className="service-action__container">
                 <div className="flex-container">
                            <div className="title1">{title1}</div>
                            <div className="title2">{title2}</div>
                            <div className="title3">{title3}</div>
                        </div>
                <Jumbotron fluid className="service-action__jumbotron" >
                    <div fluid className="">
                     <p className="service-action-description">{description}</p>
                        <ContactForm class={classForGT} />
                    </div>
                </Jumbotron>
            </div>


        );
    }
}

export default ServicesAction;
