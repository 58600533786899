import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CardListIndex,  ServicesHero, ServicesAction } from '../../../../Base/components'
import { appDevelopmentServiceUrl, appModernizationServiceUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './EnterpriseApps.scss';
import Capabilities from '../../../../Base/components/Capabilities/Capabilities';
import Capabilities2 from '../../../../Base/components/Capabilities2/Capabilities2';


class EnterpriseApps extends PureComponent {

    render() {

        const capabilities = [
            {
                title: "Consulting",
                text: "We can help your idea get visibility with putting up the right development and design strategy in this crowded market with our consulting expertise.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Consulting-Image@2x.jpg",
                titleLink: ["Enterprise"]
            },
            {
                title: "Web",
                text: "We understand the science of enhancing user experience with a combination of human-centric approach and development ingenuity.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Web-Development-Image@2x.jpg",
                titleLink: ["Web app development"]
            },
            {
                title: "Mobile Apps",
                text: "Leveraging mobility to bring business stability and visibility for you, we make your product next gen ready with the best of technology.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Mobile-Development-Image@2x.jpg",
                titleLink: ["iOS app development"]
            },
            {
                title: "Technology",
                text: "Check out what leading technologies we work on to create elegant, robust and scalable application to move your business forward.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Technology-Image@2x.jpg",
                titleLink: ["Laravel"]
            },
            {
                title: "UX UI Design",
                text: "Turn to Unified Infotech’s extensive design expertise combining human-centered design with a story weaved to drive ROI.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-UI-UX-Design-Image@2x.jpg",
                titleLink: ["Learn more about UI/UX process"]
            },
            {
                title: "Blockchain development",
                text: "We take pride in building secure and powerful Blockchain applications- from smart contracts to cryptocurrencies.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Blockchain-Image@2x.jpg",
                titleLink: ["Learn more"]
            },
            {
                title: "eCommerce",
                text: "Make your business online market ready with our distinctive ecommerce web and app development solutions.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-eCommerce-Image@2x.jpg",
                titleLink: ["How we do it"]
            },
            {
                title: "Custom software development",
                text: "Web or mobile, we have devised the best custom software development solution following agile methodology.",
                imgLink: "https://www.unifiedinfotech.net/assets/images/Service-Page-Custom-Software-Image@2x.jpg",
                titleLink: ["Learn more"]
            },

        ]

        const breadcrumbs = [
            // { children: "Services/", href: servicesUrl() },
            { children: "EnterpriseApps Services" },
        ]

        const serviceActionDescription = `We know cloud. We help unlock real value by helping you transform your business by virtue of cloud transformation. 
         Being Cloud-Native enables Rapid Responsiveness, Innovative Features, and Zero Downtime. We help you accelerate business velocity and growth by embracing rapid change, large scale and resilence. Our expertise and focus on the Twelve-Factor App and the API First principles allow us to build true cloud-native apps.
        `


        return (
            <div>
                <PageTitle text={"Cloud Native = Future Proof"} description={"XivTech helps you build cloud native apps quickly and upskill your team along the way."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Cloud Native = Future Proof"
                    description="Achieve Competitive Advantage with Agility, Reliability, Responsiveness and Speed."
                />


                <ServicesAction
                    title1 = "Speed"
                    title2 = "Agility"
                    title3 = "Cost-Effective"
                    description={serviceActionDescription}
                    classForGT="enterpriseApps"
                />
                    
                {/* <Capabilities title={capabilities[0].title} text={capabilities[0].text} titleLink={capabilities[0].titleLink} imgLink={capabilities[0].imgLink} direction="left" />
                <Capabilities title={capabilities[1].title} text={capabilities[1].text} titleLink={capabilities[1].titleLink} imgLink={capabilities[1].imgLink} direction="right" />
                <Capabilities title={capabilities[2].title} text={capabilities[2].text} titleLink={capabilities[2].titleLink} imgLink={capabilities[2].imgLink} direction="left" />
                <Capabilities2 title={capabilities[4].title} text={capabilities[4].text} titleLink={capabilities[4].titleLink} imgLink={capabilities[4].imgLink}/>
                 <Capabilities2 title={capabilities[5].title} text={capabilities[5].text} titleLink={capabilities[5].titleLink} imgLink={capabilities[5].imgLink}/>
                 <Capabilities2 title={capabilities[6].title} text={capabilities[6].text} titleLink={capabilities[6].titleLink} imgLink={capabilities[6].imgLink}/>
                 <Capabilities2 title={capabilities[7].title} text={capabilities[7].text} titleLink={capabilities[7].titleLink} imgLink={capabilities[7].imgLink}/>
                <Capabilities title={capabilities[3].title} text={capabilities[3].text} titleLink={capabilities[3].titleLink} imgLink={capabilities[3].imgLink} direction="left" /> */}
                {/* <Capabilities title={capabilities[3].title} text={capabilities[3].text} titleLink={capabilities[3].titleLink} imgLink={capabilities[3].imgLink} direction="top" />
                <Capabilities title={capabilities[3].title} text={capabilities[3].text} titleLink={capabilities[3].titleLink} imgLink={capabilities[3].imgLink} direction="top" /> */}
                 

                <Container>


                    <Row>
                        <Col>

                            <CardListIndex
                                headerName={"Capabilities"}
                                cards={[
                                    {
                                        title: "Build Apps - Deliver New apps Users Love",
                                        // titleSubtitle: "ci cd",
                                        // titleText: "Deliver New apps Users Love",
                                        asset: "/tile1.png",
                                        id: "1",
                                        to: appDevelopmentServiceUrl()
                                    },
                                    {
                                        title: "Modernize Apps - Rearchitect Valuable Legacy Apps",
                                        // titleSubtitle: "Rearchitect Valuable Legacy Apps",
                                        titleText: "",
                                        asset: "/tile2.png",
                                        id: "2",
                                        to: appModernizationServiceUrl()
                                    }
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}


export default EnterpriseApps;
