// routing
import React, { PureComponent } from 'react';
import './NotFound.scss'
class NotFound extends PureComponent {

    render() {
        return (

            <div className="not-found">
                <img src="/page-not-found.jpg" alt=""/>
            </div>
        );
    }
}


export default NotFound;
