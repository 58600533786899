import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList, ServicesHero, ServicesAction } from '../../../../Base/components'
import { rootUrl, simplifyCloudOpsUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';


class BuildDeployScaleBots extends PureComponent {

    render() {
        const breadcrumbs = [
            // { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            // { children: 'Simplify Cloud Ops', href: simplifyCloudOpsUrl() },
            // { children: 'Multi-Cloud Flexibility' }
        ]

        const serviceActionDescription = `Our expertise with bot automation helps you achieve speed, quality and reliability in your automated workflows. We help bring about a transformed, efficient and cost-effective digital experience by strategizing, designing and building the bot ecosystem. We train your team and empower you to stay ahead of the competition.`

        return (
            <div>
                <PageTitle text={"Build, Deploy and Scale bots"} description={"Helps to Build, Deliver, and Scale Containerized Apps at XivTech. Learn More! Portable. Highly Secure. Scalable. Extensible. Services: Ship Promptly, Operate with Ease."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs}/>
                <ServicesHero
                    asset={"/rp5.png"}
                    header="Bot Automation Simplified"
                    description="Build, Deploy and Scale bots"
                />

                <ServicesAction
                    title1 = "Quality "
                    title2 = "Relability"
                    title3 = "Speed"
                    description={serviceActionDescription}
                    classForGT="kubernetesServices"
                />

                {/* <Container> */}


                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full range of enterprise ready solutions to help maximize the AI + RPA synergy."}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "AI / RPA Advisory Services",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "Our AI / RPA advisory experts will work with you to devise the best automation strategy, tailored to your unique needs. Our expertise and focussed approach allows us to establish and optimize the AI + RPA automation model suited to achieve your business objectives.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "Ekisu",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Our flagship AI automation product helps you with easy document processing automation, forming a key gateway to successful automation and ecosystem synergy.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "WhatsApp/Bot Integration",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "Ekisu - our flagship automation product allows you to integrate flows with WhatsApp. It synergizes  the ecosystem by uniquely integrating and automating WhatsApp with various AI bots.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "Process Automation",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-2/summarytile/parsys.coreimg.png/1579165114555.png",
                                        // subTitle: "",
                                        description: "We help you with Building, Integrating and Testing Process Automation. It allows for transparency in the development cycle, saving valuable time and bringing in speed.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "5",
                                        title: "Bot Automation",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-1/summarytile_copy_cop/parsys.coreimg.png/1579165126972.png",
                                        // subTitle: "",
                                        description: "Our automation experts help you build, deploy and scale bots. Bot automation brings about the true benefits of AI/ML, helping you with successful Digital Transformation.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "6",
                                        title: "End to End Integration",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "Our specialists work on automating entire business flows while reusing the existing systems and capabilities.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "7",
                                        title: "RPA Analytics",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "Our core focus on dashboards and analytics allows us to design and build solid RPA analytics platform. This helps to keep tabs on the automation pulse, allowing for greater transparency and efficiency.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "8",
                                        title: "Security",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "We take security seriously, hence we take care of security considerations right from the design phase. Role-based access/configuration, encryption, logging, audit trails, SOX, HIPAA or PCI DSS compliance are some of the features which allow for robust security.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },

                                ]}
                            />
                        </Col>
                    </Row>
            </div>
        );
    }
}


export default BuildDeployScaleBots;
