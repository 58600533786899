import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import './SolvingTogether.scss'
// import {PageTitle} from '../../../Base/components/'



class SolvingTogether extends Component {
    render() {
        return (
            <div className="solvingTogether">
                 {/* <PageTitle text={"Solving Together"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} /> */}
                <Row>
                    <Col md={6}>
                        <h2>Solving Together</h2>
                        <p>
                        At XivTech, we are the multicloud solutions experts. Our technical acumen with the world’s leading technologies — across your applications, data and security — enables you to grow your business, increase efficiency and deliver the future.
                        </p>
                        <p>
                        We solve more than workload problems; we create business advantages. Our multicloud solutions expertise means you’re empowered to work faster, smarter and ahead of what’s next.
                        </p>
                    </Col>
                    <Col md={{size:6,offset:1}}>
                           <div  className="imgContainer">

                          <div className="background1"></div>
                         <div className="background2"></div>
                            <div className="imgInnerContainer">
                             <img src="/solvingTogether.webp" alt="imageNotAvailable"/>
                            </div>
                           </div>
            
                    </Col>
                </Row>
            </div>
        )
    }
}

export default SolvingTogether
