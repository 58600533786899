import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle, CapabilitiesList,ServicesHero, ServicesAction } from '../../../../Base/components';
import { rootUrl, cloudServiceHomeUrl} from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './AzureServices.scss';


class AzureServices extends PureComponent {

    render() {
        const breadcrumbs = [
            { children: 'Home', href: rootUrl() },
            // { children: 'Services', href: servicesUrl() },
            { children: 'Cloud Services', href: cloudServiceHomeUrl() },
            // { children: 'Azure Services' }
        ]

        const serviceActionDescription =
            `Cloud computing allows for unlocking new business value, enabling new and flexible models as well as the agility to compete in the rapidly changing world. We enable the cloud journey for our customers by strategizing around unique customer needs and offering expert solutions. Our core focus on rationalization allows us to unlock the true value of Azure. We frame tailored and risk-mitigated cloud strategy solutions which consider all possibilities and minimal downtime.`;


        return (
            <div>
                <PageTitle text={"Cloud Services with Azure"} description={"Unlock business value, realize cost savings, and make your organization more efficient with Microsoft Azure's open and flexible cloud computing platform at XivTech."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />

                <ServicesHero
                    asset={"/main-service1.jpeg"}
                    header="Cloud Services with Azure"
                    description="Accelerate digital change by developing, migrating, and deploying apps in Azure."
                />

                <ServicesAction
                    title1=  "On-Prem"
                    title2 = "Hybrid"
                    title3 = "Multi-Cloud"
                    description={serviceActionDescription}
                    classForGT="Azure"
                />

                {/* <Container> */}
                    <Row>
                        <Col>
                            <CapabilitiesList
                                headerName={"Capabilities"}
                                capabilitiesDescription={"We offer a full spectrum of enterprise ready solutions to help maximize the benefits of Microsoft Azure Cloud Services."}
                                capabilities={[
                                    {
                                        id: "1",
                                        title: "Cloud First Advisory",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "Our Cloud advisory experts will work with you to devise the best Cloud First Digital Transformation Strategy, tailored to your unique needs. Our focussed business outcome framework allows us to clearly help you identify business outcomes to ensure a successful cloud transformation.",
                                        list: [
                                            // "Find out how our Advisory Services can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "2",
                                        title: "App Modernization",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Our unique rationalization approach allows us to Rehost, Refactor, Rearchitect, Rebuild or Replace applications to a modern microservice architecture.",
                                        list: [
                                            // "Find out how our Microservices Architecture can benefit you:",
                                            // "Contact Us Today",
                                        ],
                                    },
                                    {
                                        id: "3",
                                        title: "Cloud Migration",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "We help you execute your cloud strategy by helping you move to the cloud while keeping your business moving. We rationalize your application portfolio and help migrate you to your preferred cloud offering.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "4",
                                        title: "Hybrid Cloud",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-1/summarytile_copy/parsys.coreimg.png/1579165112474.png",
                                        // subTitle: "",
                                        description: "We specialize in architectural design, that accommodates hybrid private and public cloud strategies enabling you to future proof your digital transformation. Our expertise allows for a true hybrid cloud experience that increases efficiency, flexibility and productivity.",
                                        list: [

                                            // "Application Modernization",
                                            // "Data Modernization",
                                        ],
                                    },
                                    {
                                        id: "5",
                                        title: "Cloud Security",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Our expertise allows us to help you transition to a fast and secure cloud. Our solutions provide guardrails to enforce security practices with the flexibility for continuous iteration that digital business demands.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "6",
                                        title: "App Containerization ",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-2/summarytile/parsys.coreimg.png/1579165114555.png",
                                        // subTitle: "",
                                        description: "We help you improve agility by developing and implementing a containerization strategy suited uniquely to your needs. We employ tools and frameworks such as Docker and Kubernetes to devise and implement the best DevOps strategy for your business.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "7",
                                        title: "Cloud-Native Apps",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "Our Cloud expertise allows us to co-create and deploy cloud-native applications with modern microservice and dev-ops architecture. We help transfer skiils to your team to dramatically scale innovation and expertise across your organization.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "8",
                                        title: "CI/CD",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-2/summarytile_copy/parsys.coreimg.png/1579165160021.png",
                                        // subTitle: "",
                                        description: "Our expertise with continuous integration, and continuous delivery (CI/CD) helps you achieve pace and code quality with your releases. We help strategize, design and implement the CI/CD pipelines, enabling greater release consistency and reliability. We train your team and empower you to stay ahead of the competition.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "9",
                                        title: "Cloud-Native Microservices",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-0/summarytile_copy/parsys.coreimg.png/1579165131889.png",
                                        // subTitle: "",
                                        description: "We help you drive speed and agility to deliver business value by strategizing and implementing microservices. Our microservices expertise allows us to deliver technology quickly, bringing faster go to market cycle times.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    }, 
                                    {
                                        id: "10",
                                        title: "Data Migration",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-1/summarytile_copy_cop/parsys.coreimg.png/1579165126972.png",
                                        // subTitle: "",
                                        description: "Our expertise allows us to deploy solutions which enable smooth and secure data transitions to the cloud, preserving the data quality, integrity and security.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "11",
                                        title: "AI Services",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1811642221/responsivegrid-2/summarytile/parsys.coreimg.png/1579165114555.png",
                                        // subTitle: "",
                                        description: "Our AI expertise allows us to help you add intelligence to your apps. We build and train machine learning models, discover new opportunities through knowledge mining and deploy AI solutions to help your business achieve NextGen competitive advantage with AI.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    {
                                        id: "12",
                                        title: "Blockchain Services",
                                        img:"https://www2.asx.com.au/content/asx/home/markets/trade-our-derivatives-market/_jcr_content/root/responsivegrid/multicolumncontrol_1_1112318830/responsivegrid-0/summarytile/parsys.coreimg.png/1579165101816.png",
                                        // subTitle: "",
                                        description: "Our Blockchain expertise, allows us to help you unlock efficiencies across your business and partner network. We help you form, manage and govern your blockchain, creating smart contracts for achieving maximum value and impact. We craft solutions on Hyperledger or Azure, depending on your unique needs and business objectives.",
                                        list: [
                                            // "Service Item 1",
                                            // "Service Item 2",
                                        ],
                                    },
                                    // {
                                    //     id: "13",
                                    //     title: "Containers",
                                    //     // subTitle: "",
                                    //     description: " Containerize applications with Azure container services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "14",
                                    //     title: " Databases",
                                    //     // subTitle: "",
                                    //     description: "Homogenous and complex heterogeneous database migrations to Azure Managed Data Services",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "15",
                                    //     title: "Data on cloud",
                                    //     // subTitle: "",
                                    //     description: " Drive accelerated migration to the cloud with enhanced data trust and quality.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "16",
                                    //     title: "Assessment Services",
                                    //     // subTitle: "",
                                    //     description: " Bring your business vision to life with our custom cloud migration plan and assessment services.",
                                    //     list: [
                                    //         "Cloud assessment services",
                                    //         "Business drivers identification and assessment",
                                    //     ],
                                    // },

                                    // {
                                    //     id: "17",
                                    //     title: "Infrastructure Modernization",
                                    //     // subTitle: "",
                                    //     description: "Transition your IT infrastructure to the cloud and accelerate innovation with Azure cloud services.",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },
                                    // {
                                    //     id: "18",
                                    //     title: "Legacy App Modernization",
                                    //     // subTitle: "",
                                    //     description: " Breathe life and longevity into your legacy apps with our App Modernization Methodology (WinAMM)..",
                                    //     list: [
                                    //         "Service Item 1",
                                    //         "Service Item 2",
                                    //     ],
                                    // },


                                ]}
                            />
                        </Col>
                    </Row>
                    

                    {/* look up where the accenture pointers weere */}
                {/* </Container> */}

            </div>
        );
    }
}


export default AzureServices;
