import React from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import { string, node, bool } from 'prop-types';

// get rid of the todo
// expand the container width a little bit similar to dx

const Page = props => (
    <Container
        className={classNames('page', props.className)}
        fluid={props.fluid}
    >
        {props.children}
    </Container>
)

export default Page;


Page.propTypes = {
    children: node,
    className: string,
    fluid: bool,
};

Page.defaultProps = {
    children: null,
    className: undefined,
    fluid: false,
};