import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import './HomeBanner.scss';
import { PageTitle } from '../../../Base/components/'
import HomePageHeader from '../HomePageHeader/HomePageHeader';
import ServicesBanner from '../ServicesBanner/ServicesBanner';
import ContactForm from '../ContactForm/ContactForm';
import AnimateDesign from '../AnimateDesign/AnimateDesign';

class HomeBanner extends PureComponent {
    constructor(props) {
        super(props)
        this.videoRef = React.createRef();
    }

    // componentDidMount() {
    //     this.videoRef.current.playbackRate = 0.25;
    // }
    render() {

        const {
            indexName,
            // asset,
        } = this.props;

        // const videoAsset = {
        //     mpv4: asset + ".mp4",
        //     ogv: asset + ".ogv",
        //     webm: asset + ".webm",
        // };

        return (
            <div className="landing-page">
                <div className="homePageMainComponent">
                    <HomePageHeader />
                <AnimateDesign />
                    <div className="servicesBannerComponent">
                        <div className="collaborate">
                            <h4>Let's Collaborate</h4>
                        </div>
                        <ServicesBanner />
                    </div>

                    <div className="getInTouchContainer">
                        <ContactForm />
                    </div>
                </div>


                <PageTitle text={"Bot, Process and Efficiency"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} />
                {/* <div>
                                <h1 className="landing-page-text" >{indexName}</h1>
                            </div> */}
                {/* <div>
                                <FontAwesomeIcon className="landing-page-icon fa-10x icon" icon={faAngleDown} />
                            </div>
                             <div className="background"></div>
          
                            <video className="landing-page-video" muted autoPlay loop id="landing-page-video" ref={this.videoRef} >
                                <source src="/homevideo.mp4" autoplay type="video/mp4" />
                                <source src="/homevideo2.ogg" type="video/ogg"></source>
                            </video>  */}
            </div>
        );
    }
}

export default HomeBanner;

HomeBanner.propTypes = {
    indexName: string.isRequired,
    indexDescription: string.isRequired,
    to: string.isRequired,
};
