import React, { PureComponent } from 'react';
import { Row, Col, Container,  Jumbotron } from "reactstrap";
import './PartnersTile.scss';

class PartnersTile extends PureComponent {

    render() {

        const {
            images,
        } = this.props;



        return (
            <div>
                <div className="partners-tile">
                    <Row>
                        <Col className="partners-tile-header" md="12">
                            <h2>
                                Innovation Tools and Partners
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div >
                                <Row>
                                    {
                                        images.map(image => (
                                            <Col md="3">
                                                <img className="partners-tile-image" src={image} alt="img" />
                                            </Col>

                                        ))
                                    }
                                </Row>

                            </div>
                        </Col>
                    </Row>

                </div>
                <Jumbotron fluid>
                    <Container fluid>
                        <h1 className="display-6">PartnersTile2</h1>
                        <p className="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
                    </Container>
                </Jumbotron>
            </div>


        );
    }
}

export default PartnersTile;
