import React, { PureComponent } from 'react';
import './FooterLocations.scss';

class FooterLocations extends PureComponent {

    render() {

        const {
            locations,
        } = this.props;



        return (
            <div>
                <h7 className="footer-locations-header">
                    {locations.header}
                </h7>
                <div className="footer-locations-items mt-3 d-flex flex-column justify-content-end">
                    {
                        locations.list.map(location => (
                            <p className="m-1">
                                {location}
                            </p>
                        ))
                    }
                   
                </div>
            </div>
        );
    }
}

export default FooterLocations;
