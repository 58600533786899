import React, { PureComponent } from 'react';
import { Jumbotron, Container } from 'reactstrap';

import {PageTitle} from '../../../Base/components/'
import './ReachoutTile.scss';

class ReachoutTile extends PureComponent {

    render() {

        // const {
        //     title,
        // } = this.props;



        return (
            <Jumbotron>
                 <PageTitle text={"Begin Your Digital Transformation"} description={"XivTech helps enterprises reduce the complexities of cloud automation. Our IT services portfolio creates a secure path for operational excellence."} />
                <Container fluid className="reachOut">
                    <h2 className="display-6">Begin Your Digital Transformation</h2>
                    <p className="lead">
                        Let’s work together to identify your needs and outline a plan of action to help you achieve your IT goals.</p>
                </Container>
            </Jumbotron>

        );
    }
}

export default ReachoutTile;
