import React, { PureComponent } from 'react';
import { BreadcrumbNavIndex, PageTitle,CardListIndex, ServicesHero, ServicesAction } from '../../../../Base/components'
import {  azureServiceUrl,awsServiceUrl } from '../../../../Base/helpers/url'
import { Container, Row, Col } from 'reactstrap';

import './CloudServicesHome.scss';


class CloudServicesHome extends PureComponent {

    render() {
        const breadcrumbs = [
            // { children: "Services/", href: servicesUrl() },
            { children: "Cloud Services" },
            { children: 'AWS', href: "/services/cloud/aws" },
            { children: 'Azure', href: "/services/cloud/microsoft-azure" },
            // { children: 'Insights', href: "#insights" },
        ]

        const serviceActionDescription = `Achieve business outcomes by transforming to cloud. Our unique capabilities allow us to help you,  Discover New Revenue Streams, Reduce Cycle Time, Bring Scale and Reliability, Decrease Time to Market, Future Proof your Digital Estate.
        Why stop here? Go beyond with adopting cloud to integrate new forms of technologies to speed up, automate and improve your business. Cloud Computing integrates technologies such as Artificial Intelligence (AI), Machine Learning, Big Data Analytics, and the Internet of Things (IoT), helping you to achieve your business goals.`;
        return (
            <div>
                <PageTitle text={"Cloud Services = Business Outcomes"} description={"Achieve business outcomes by transforming to cloud at XivTech where we provide cloud service like Azure and AWS."} />
                <BreadcrumbNavIndex breadcrumbs={breadcrumbs} />
                <ServicesHero
                    // asset={"/main-service1.jpeg"}
                    asset={"/m1.webp"}
                    header="Cloud Transition = Business Outcomes"
                    description="Rapidly - Rehost, Refactor, Rearchitect, Rebuild and Replace applications to transform and achieve business goals."
                />
                <ServicesAction 
                    title1=  "On-Demand"
                    title2 = "Transformation"
                    title3 = "Utilization"
                    description={serviceActionDescription}
                    classForGT="cloudServicesHome"
                />


                {/* <Row>
                    <Col>
                        <TeaserTile
                            serviceHeader={"We know cloud. We help unlock real value by helping you transform your business by virtue of cloud migration."}
                            serviceDescription={
                                [
                                    "Achieve business outcomes by transforming to cloud. Our unique capabilities allow us to help you:",
                                ]
                            }
                            serviceList={
                                [
                                    "Discover new revenue streams",
                                    "Reduce Cycle Time",
                                    "Bring Scale and Reliability",
                                    "Decrease Time to Market",
                                    "Future Proof your Digital Estate",
                                ]
                            }
                            asset={"/cloud-service-index.jpg"}
                        />
                    </Col>
                </Row> */}

                <Container>


                    <Row>
                        <Col>

                            <CardListIndex
                                headerName={"Capabilities"}
                                cards={[
                                    {
                                        title: "Cloud Services for Microsoft Azure ",
                                        titleSubtitle: "Innovation, Scale, DevOps, Security & Compliance with Azure Cloud",
                                        titleText: "",
                                        asset: "/tile1.png",
                                        id: "1",
                                        to: azureServiceUrl()
                                    },
                                    {
                                        title: "Cloud Services for AWS ",
                                        titleSubtitle: "Innovation, Scale, DevOps, Security & Compliance with AWS",
                                        titleText: "",
                                        asset: "/tile2.png",
                                        id: "1",
                                        to: awsServiceUrl()
                                    },
                                    // {
                                    //     title: "Cloud-Native Applications ",
                                    //     titleSubtitle: "Innovation, Scale, Business Velocity, Responsiveness & Zero Downtime",
                                    //     titleText: "",
                                    //     asset: "/tile2.png",
                                    //     id: "2",
                                    //     to: appDevelopmentServiceUrl()
                                    // },
                                    // {
                                    //     title: "Continuous Integration and Continuous Delivery ",
                                    //     titleSubtitle: "Build, Integration & Testing Process Automation",
                                    //     titleText: "",
                                    //     asset: "/tile3.png",
                                    //     id: "3",
                                    //     to: "/cloud3"
                                    // },
                                    // {
                                    //     title: "Serverless and Containers",
                                    //     titleSubtitle: "Container Orchestration / Kubernetes",
                                    //     titleText: "Automation, Relability, Portability & Scale",
                                    //     asset: "/tile3.png",
                                    //     id: "4",
                                    //     to: "/cloud4"
                                    // },
                                    // {
                                    //     title: "Cloud Native Applications ",
                                    //     titleSubtitle: "sub title",
                                    //     titleText: "",
                                    //     asset: "/tile1.png",
                                    //     id: "5",
                                    //     to: "/cloud5"
                                    // },
                                    // {
                                    //     title: "Continous Integration & Continous Deployment ",
                                    //     titleSubtitle: "sub title",
                                    //     titleText: "",
                                    //     asset: "/tile2.png",
                                    //     id: "6",
                                    //     to: "/cloud6"
                                    // },

                                ]}
                            />
                        </Col>
                    </Row>
                    {/* How we work//
                    //Link to a new Page
                    //fix the link bug
                    //footer
                    Cloud And Platform Services Main Page */}
            </Container>

            </div>
        );
    }
}


export default CloudServicesHome;
