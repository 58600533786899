
import React, { Component } from 'react'
import './jobDescription.scss'
import { Helmet } from "react-helmet";
import ApplicationForm from '../ApplicationForm/ApplicationForm';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
// import { useLocation} from "react-router-dom"

class jobDescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
           id : props.location.id,
           title : props.location.title,
           location : props.location.location,
           description:{
               jobSummary: props.location.jobSummary,
               responsibilities : props.location.responsibilities,
               basicQualifications : props.location.basicQualifications,
               requiredEducation : props.location.requiredEducation
           },
           category: props.location.category
        }
        console.log(this.state.title);
        
        // const title = props.location.userProps.title;
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <html lang="en" />
                    <title>Job-description</title>
                    <meta name="description" content="Tutorial for React Helmet" />
                    <meta name="theme-color" content="#E6E6FA" />
                </Helmet>
                <h5>{this.state.category}</h5>
                <Row>
                    <Col md={7}>
                      <h6>{this.state.title}</h6>
                    </Col>
                    <Col md={5} style={{textAlign:"right"}}>{this.state.location}</Col>
                </Row>

                <div className="details">
                    <Row className="d-flex justify-content-center">
                        <div className="">
                            <ApplicationForm />
                        </div>
                    </Row>
                    <h5>Job Summary:</h5>
                    <ul>
                       {this.state.description.jobSummary}
                    </ul>

                    <h5>Responsibilities:</h5>
                    <ul>
                    {this.state.description.responsibilities}
                    </ul>
                    {/* <h5>Basic Qualifications:</h5>
                    <ul>
                    {this.state.description.basicQualifications}
                      
                    </ul>
                    <h5>Required Education</h5>
                    <ul>
                    {this.state.description.requiredEducation}
                    </ul> */}
                    <Row className="d-flex justify-content-center">
                        <div className="mt-5">
                            <ApplicationForm jobTitle={this.state.title} />
                        </div>
                    </Row>

                </div>


            </div>
        )
    }
}

export default jobDescription
